import ReactGA from 'react-ga4';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Context } from '../../DataStore';

import * as strings from '../../data/strings';

import '../../styles/marketing/Hero.scss';

const Hero = (props) => {
  const { store } = useContext(Context);
  const history = useHistory();

  const goLogin = () => {
    ReactGA.event({
      category: "Marketing",
      action: "Login - Header"
    });
    history.push('/login/');
  }

  const goRegister = () => {
    ReactGA.event({
      category: "Marketing",
      action: "Register - Header"
    });
    history.push('/register/');
  }

  return (
    <div className="Hero">
      <div className="menuBar">
        <img
          className="gravityLogo"
          src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}svg/bright-village-white.svg`}
          alt="Bright Village Logo" />
        <div className="buttonsWrapper">
          <div className="buttonWrapper">
            <button
              className="headerButton startHere"
              onClick={() => goRegister()}>
              {strings.default[store.language].Marketing.StartFree}
            </button>
          </div>
          <div className="buttonWrapper">
            <button
              className="headerButton logIn"
              onClick={() => goLogin()}>
              {strings.default[store.language].Marketing.LogIn}
            </button>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div className="content">
          <div className="headerContent">
            <div className="headerSection text">
              <div className="headerTextWrapper">
                <div className="headerText">
                  {strings.default[store.language].Marketing.HeroTitle}
                </div>
                <div className="headerSubtext">
                  <span className="bold">{strings.default[store.language].Marketing.HeroDescriptionBold}</span> {strings.default[store.language].Marketing.HeroDescription}
                </div>
              </div>
            </div>
            <div className="headerSection graphic">
              <img className="headerGraphic" src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}png/section-2-thought-graphic.png`} alt="Hero Graphic" />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Hero;

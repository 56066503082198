const strings = {
    en: {
        Marketing: {
            StartFree: 'Start For Free',
            LogIn: 'Log In',
            HeroTitle: 'Evolve Your Learning & Development',
            HeroDescriptionBold: 'All-in-one asynchronous video learning and collaboration platform',
            HeroDescription: 'that powers real interaction and instant feedback.',
            AIAssistedDiscussions: 'Create From Anywhere',
            AIAssistedDiscussionsDescription: 'Record video courses, add content, assign recorded responses, and measure feedback right from your computer. Get insights that reveal comprehension levels, embracing of ideas, employee morale and so much more.',
            KeywordsInsights: 'Save Time And Money',
            KeywordsInsightsDescription: 'Unique AI voice recognition and keyword/phrase dectection indicate true understanding of the content. Most importantly, it builds a holistic statistical analysis so your department knows that your initiatives are actually working - saving time, resource, and costly mistakes.',
            DifferentiatedLearning: 'Differentiated Learning',
            DifferentiatedLearningDescription: 'Bright Village enables you to carve out a path that is unique to your learner\'s style, vary instructional strategies, and provide multiple ways to express their understanding.',
            CallOut: 'Build a modern L&D program that is not just about learning, but about powerful growth, together.',
            ThoughtEquity: 'Drive Real Success',
            ThoughtEquityDescription: 'Skip the slideshows, fast-forwarding of videos, and the multiple-choice guessing. Get to the heart of the issue and connect with others to put the pieces together. Bright Village ensures your workforce is raising the bar and living the values that drive success.',
            
            ResearchBacked: 'Research-Backed',
            ResearchBackedDescription: 'Bright Village was selected for quantitative research at Johns Hopkins University School of Education and was found to significantly increase learning outcomes.',
            ByEducators: 'Built By The Best',
            ByEducatorsDescription: 'Built by a team of educators, ex-Fortune 500, and technologists who believe that async collaboration changes L&D forever.',
            
            MagicAIPrompts: 'AI Prompts',
            UnlimitedRecording: 'Unlimited Recording',
            AIVoiceToneAnalysis: 'Instant insights into tone, sentiment, and originality',
            ReadyToSupercharge: 'Ready to evolve your Learning & Development?',
            YesIAm: 'Yes, I am - Let\'s go!',
            
            StartForFree: 'Start For Free',
            StartForFreeDescription: 'Your people deserve it!',
            StartForFreeCallout: 'No Credit Card Required',
            AffordablePricing: 'Affordable Pricing',
            AffordablePricingDescription: 'Just $8 / month or $76 / year',
            AffordablePricingCallout: '20% Discount on Yearly!'
        },
        Header: {
            Logout: 'Logout',
            EditProfile: 'Edit Profile',
            MonthlyRecordingLimit: 'Monthly Recording Limit',
            MinutesRemaining: 'Minutes Remaining'
        },
        Register: {
            Greeting: 'Howdy',
            GetRegistered: 'Let\'s get you registered',
            HaveJoinCode: 'I have a Join Code',
            CreateCommunity: 'Create a New Community',
            JoinCode: 'Join Code',
            NewCommunity: 'New Community',
            SixCode: 'Please enter your 6 character code',
            CommunityName: 'What do you want to name your community?',
            CreateAccount: 'Create Account',
            ToJoin: 'to join ',
            ThisCommunity: 'this community',
            ToStart: 'to start ',
            YourCommunity: 'your community',
            Now: ' now'
        },
        Dashboard: {
            BookDemo: 'Book a Bright Village 15-Minute Live Demo for FREE!',
            Dashboard: 'Dashboard',
            Watched: 'Watched',
            Videos: 'Videos',
            Created: 'Created',
            Recordings: 'Recordings',
            Responded: 'Responded',
            Conversations: 'Conversations',
            CreatorStudio: 'Creator Studio',
            QuickPrompt: 'Quick Prompt',
            MagicAIPrompt: 'Bright AI Prompt',
            MagicAI: 'Bright AI',
            Left: 'Left',
            Unlock: 'Unlock',
            RecentVideos: 'Recent Videos',
            Engagement: 'Engagement',
            QuickMenu: 'Quick Menu',
            CheckMirror: 'Check Mirror'
        },
        Search: {
            NoResults: 'No results',
            Create: 'Create'
        },
        LoginRegister: {
            EmailAddress: 'Email Address',
            Password: 'Password',
            Login: 'Login',
            Register: 'Register',
            FullName: 'Full Name',
            FullNamePlaceholder: 'Jane Johnson',
            CommunitySearch: 'Community Search',
            CommunitySearchPlaceholder: 'Search for Community',
            CheckAvailability: 'Check Availability',
            AccountType: 'I am a',
            Uppercase: 'Uppercase Letter',
            Lowercase: 'Lowercase Letter',
            Number: 'Number',
            SpecialCharacter: 'Special Character',
            MinimumCharacters: 'Minimum Characters',
            RestoringSession: 'Restoring session',
            LogInToGravity: 'Log in to Bright Village',
            OrAreYouNew: 'Or are you new?',
            ForgotPassword: 'Forgot Password?',
            Request: 'Request',
            GoBack: 'Go Back',
            RequestSent: 'Request Sent',
            Continue: 'Continue'
        },
        Package: {
            Basic: 'Basic',
            RecordAndShare: 'Record + Share',
            Free: 'Free',
            Includes: 'Includes',
            ThirtyMin: '30-Min Shared Recording Limit',
            Community: 'Community',
            Folders: 'Folders',
            GetStarted: 'Get Started',
            Premium: 'Premium',
            Unlimited: 'Unlimited',
            UnlimitedEverything: 'Unlimited Everything',
            AIWorkflows: 'AI Workflows',
            PerMonth: 'per month',
            EverythingInBasicPlus: 'Everything in Basic, plus',
            UnlimitedRecordingLimit: 'Unlimited Recording',
            UnlimitedCommunities: 'Unlimited Communities',
            UnlimitedFolders: 'Unlimited Folders',
            AIVoiceToneAnalysis: 'AI Voice Tone Analysis',
            AIAutoTranscription: 'AI Auto Transcription',
            AIKeywordsAndInsights: 'AI Keywords & Insights',
            StartNow: 'Start Now',
            Enterprise: 'Enterprise',
            AdminAndAnalytics: 'Admin + Analytics',
            Custom: 'Custom',
            EverythingInPremiumPlus: 'Everything in Premium, plus',
            AnalyticsAndUsage: 'Analytics & Usage',
            DocumentAttachments: 'Document Attachments',
            DocumentAttachmentsDescription: 'Attach documents and Youtube videos to provide additional context!',
            MonthlyEngagementReports: 'Monthly Engagement Reports',
            DedicatedAccountManager: 'Dedicated Account Manager',
            TwentyFourSevenSupport: '24/7 Support',
            LetsTalk: 'Let\'s Talk',
            OneMinuteRecordingLimit: '1-Minute Recording Limit',
            UnlimitedMagicAIPrompts: 'Unlimited AI Prompts',
            UnlimitedMagicAIPromptsDescription: 'AI Prompts create engaging topics with a single click',
            UnlimitedRecordingLimitDescription: 'Record as much as you want without any limitations',
            AIVoiceToneAnalysisDescription: 'Instant insights into tone, sentiment, and originality against ChatGPT',
            AIAutoTranscriptionDescription: 'Increase accessibility, engagement, and learning outcomes',
            AIKeywordsAndInsightsDescription: 'Keywords extraction, instant definitions and suggested content',
            AnalyticsAndUsageDescription: 'Detailed reports usage, engagement, what\'s working and what\'s not'
        },
        Menu: {
            Select: 'Select',
            Create: 'Create',
            CommunityBegin: 'a community to begin.',
            Communities: 'Communities',
            Folders: 'Folders',

            AddCommunity: 'Add Community',
            AddCommunityDescription: 'Join or create a community for people to collaborate, converse and discover what\'s next.',
            AddCommunityName: 'Community Name',
            AddCommunityPlaceholder: 'ex. DEJ073',
            CreateCommunityPlaceholder: 'ex. Mulberry Street School',

            AddJoinCode: 'Enter Join Code',
            AddJoinCodeDescription: 'Join your classmate community to collaborate, converse and discover what\'s next.',
            AddJoinCodeName: 'Join Code',
            AddJoinCodePlaceholder: 'ex. 0XF78D',

            CreateFolder: 'Create a folder',
            CreateFolderDescription: 'Gather people in your community into focussed groups to discuss anything. Build collaborative conversations, polls and more.',
            CreateFolderName: 'Folder Name',
            CreateFolderPlaceholder: 'ex. European History Class',
            UniqueName: 'Unique Name',

            ConfirmDelete: 'Delete Folder',
            ConfirmDeleteDescription: 'Are you sure you want to delete',
            ConfirmDeleteDiscussion: 'Delete Discussion',
            ConfirmDeleteRecording: 'Delete Recording',

            CreateFolderStart: 'Create a folder to start collaborating!',
            Duplicate: 'Duplicate',
            ConfirmDuplicate: 'Duplicate Folder',
            ConfirmDuplicateDescription: 'will be duplicated without the responses. Please enter a unique name for the new folder.',
        },
        Modal: {
            Cancel: 'Cancel',
            Submit: 'Submit',
            Loading: 'Loading',
            Uploading: 'Uploading',
            Close: 'Close',
            Delete: 'Delete',
            ShortDescription: 'Prompt Description (Required)',
            ShortDescriptionPlaceholder: 'Please speak for 2 minutes about...',
            SpaceType: 'Space Type',
            Conversation: 'Conversation',
            ConversationDescription: 'Conversations allow you to record, respond and collaborate with classmates any time.',
            Poll: 'Poll',
            PollDescription: 'Ready to weigh options or reach concensus? Start a poll and let the community speak.',
            VideoRecording: 'Video Recording',
            AttachFile: 'Attach File',
            YoutubeLink: 'Attach Youtube',
            ViewPreview: 'View Preview',
            ClosePreview: 'Close Preview',

            ChangeDescription: 'Change your full name or your profile image.',
            ProfileImage: 'Profile Image',
            ChangeHandle: '@ Name',
            ChangeHandlePlaceholder: 'Name (without @, without spaces)',

            JoinByCode: 'Join By Code',
            CreateNew: 'Create New',
            RelatedVideos: 'Related Videos',
            RelatedArticles: 'Related Articles',
            NoResults: 'No results',

            PremiumTitle: 'Unlock Premium',
            PremiumDescription: 'For only $8, unlock the most powerful AI workflow tools that save you hours of work, create entire lessons out of your prompts, and supercharge engagement',
            Upgrade: '25 Seats - $8 / Month',
            UpgradeYearly: 'Unlimited Seats - $82 / Year',
            YouArePremium: 'You are Premium',
            PremiumThankYou: 'thank you for being a',
            Monthly: 'Monthly',
            Yearly: 'Yearly',
            PremiumMember: 'Bright Village Premium Member',
            NextRenewal: 'Next Renewal',
            Savings: '15% Off + Bonus',
            ContactUs: 'Contact Us',

            Yay: 'Yay!',
            Space: 'Space',
            Response: 'Response',
            Created: 'Created!',
            OnYourWay: 'You\'re now on your way to exciting collaboration!',
            Return: 'Return',

            AIPromptAssistant: 'AI Prompt Assistant',
            PromptScript: 'Prompt Script',
            PromptScriptPlaceholder: 'Paste your script here. This area will not be recorded.',
            Hide: 'Hide',
            Show: 'Show',
            Update: 'Update',
            Private: 'Private'
        },
        Content: {
            JoinCode: 'Join Code',
            Welcome: 'Welcome',
            NoUpdates: 'No updates since your last login',
            CreateASpace: 'Create New Video',

            CreateNewPrompt: 'Create New Prompt',
            CreateSpaceDescription: 'Record your face, voice or screen and get things started. Add information to help others collaborate!',
            CreateSpaceName: 'Prompt Title (Required)',
            CreateSpacePlaceholder: 'ex. Discussion on the Age of Enlightenment',
            ResponseTitle: 'Response Title (Required)', 

            NoPromptsYet: 'No prompts yet...',
            SelectAFolder: 'Select a Folder',

            Back: 'Back'
        },
        Recorder: {
            Video: 'Video',
            Screen: 'Screen',
            Voice: 'Voice',
            Resume: 'RESUME',
            Pause: 'PAUSE',
            Recording: 'RECORDING',

            Originality: 'Originality',
            Tone: 'Tone',
            Neutral: 'Neutral',
            Positive: 'Positive',
            Negative: 'Negative',

            InfoHover: 'Bright Village leverages technology to detect responses by generative AI like ChatGPT. (This is an experimental feature)',
            ToneHover: 'Bright Village leverages technology to help detect tone based on video transcript. (This is an experimental feature)'
        },
        SpacesView: {
            Conversation: 'Conversation',
            Poll: 'Poll',
            Members: 'Members',
            Joined: 'Joined',
            Anonymous: 'Anonymous'
        },
        AssetsView: {
            Teacher: 'Teacher',
            Student: 'Student',
            Respond: 'Respond',
            To: ' to',
            RespondTo: 'Respond to',
            DocumentAttachment: 'Document Attachment',
            YoutubeAttachment: 'YouTube Attachment',
            NoResponses: 'No responses yet',
            View: 'View',
            Reply: 'Reply',
            Replies: 'Replies',

            ResponseDescriptionPlaceholder: 'ex. Before we make a decision...',
            SidebarWith: 'Sidebar with',
            ThreadDescriptionPlaceholder: 'ex. Could you expand on that detail a bit...',

            Back: 'Back to',
            Keywords: 'Keywords',
            Insights: 'Insights',
            EditKeywords: 'Edit Keywords',
            EditKeywordsDescription: 'Please keep a minimum of 5 keywords',
        },
        ThreadView: {
            Collapse: 'Collapse',
            Back: 'Back to prompt'
        },
        Upload: {
            DropFileHere: 'Drop file here',
            Or: 'OR',
            SelectFile: 'Select file',
            FileName: 'File Name',
            FileType: 'File Type',
            Reselect: 'Reselect'
        },
        Video: {
            Transcripts: '*Transcripts are automatically generated. Inaccuracies may occur.'
        }
    },
    zh: {
        Marketing: {
            StartFree: '免费开始',
            LogIn: '登录',
            HeroTitle: '发展您的学习和发展',
            HeroDescriptionBold: '一体化的异步视频学习和协作平台',
            HeroDescription: '为真正的互动和即时反馈提供动力。',
            AIAssistedDiscussions: '从任何地方创建',
            AIAssistedDiscussionsDescription: '从您的计算机直接录制视频课程，添加内容，分配录制的响应，并测量反馈。获得揭示理解水平、思想接纳、员工士气等的见解。',
            KeywordsInsights: '节省时间和金钱',
            KeywordsInsightsDescription: '独特的AI语音识别和关键字/短语检测表明对内容的真正理解。最重要的是，它构建了一个全面的统计分析，因此您的部门知道您的倡议实际上正在起作用-节省时间、资源和昂贵的错误。',
            DifferentiatedLearning: '差异化学习',
            DifferentiatedLearningDescription: 'Bright Village使您能够为学习者的风格开辟一条独特的道路,变化的教学策略,并提供多种表达理解的方式。',
            CallOut: '建立一个现代化的L&D计划,不仅仅是关于学习，而是关于强大的增长，共同成长。',
            ThoughtEquity: '推动真正的成功',
            ThoughtEquityDescription: '跳过幻灯片,快进视频,多项选择猜测。直奔问题的核心,与他人联系,将碎片放在一起。Bright Village确保您的员工正在提高标准,并实践推动成功的价值观。',
            
            ResearchBacked: '研究支持',
            ResearchBackedDescription: 'Bright Village被约翰斯·霍普金斯大学教育学院选中进行定量研究,并发现其显著提高了学习成果。',
            ByEducators: '由最好的人建造',
            ByEducatorsDescription: 'Bright Village是由一组教授、财富500强领导者和技术专家构建的,他们相信真实的基于视频的连接可以改变L&D的世界。',
            
            MagicAIPrompts: 'AI提示',
            UnlimitedRecording: '无限录制',
            AIVoiceToneAnalysis: '语气、情绪和原创性的即时见解',
            ReadyToSupercharge: '准备发展您的学习和发展？',
            YesIAm: '是的，我是 - 让我们开始吧！',
            
            StartForFree: '免费开始',
            StartForFreeDescription: '您的员工值得拥有它！',
            StartForFreeCallout: '无需信用卡',
            AffordablePricing: '价格实惠',
            AffordablePricingDescription: '仅需 $8 / 月或 $76 / 年',
            AffordablePricingCallout: '年度优惠20%'
        },
        Header: {
            Logout: '登出',
            EditProfile: '编辑个人资料',
            MonthlyRecordingLimit: '每月录制限制',
            MinutesRemaining: '剩余分钟'
        },
        Register: {
            Greeting: '你好',
            GetRegistered: '让我们注册',
            HaveJoinCode: '我有一个加入代码',
            CreateCommunity: '创建一个新社区',
            JoinCode: '加入代码',
            NewCommunity: '新社区',
            SixCode: '请输入您的6位字符代码',
            CommunityName: '您想给社区起什么名字？',
            CreateAccount: '创建帐号',
            ToJoin: '加入',
            ThisCommunity: '这个社区',
            ToStart: '开始',
            YourCommunity: '你的社区',
            Now: '现在'
        },
        Dashboard: {
            BookDemo: '免费预订 Bright Village 15 分钟现场演示!',
            Dashboard: '仪表板',
            Watched: '已观看',
            Videos: '视频',
            Created: '创建',
            Recordings: '录音',
            Responded: '已回复',
            Conversations: '对话',
            CreatorStudio: '创作者工作室',
            QuickPrompt: '快速提示',
            MagicAIPrompt: 'Bright AI提示',
            MagicAI: 'Bright AI',
            Left: '剩余',
            Unlock: '解锁',
            RecentVideos: '最近的视频',
            Engagement: '参与度',
            QuickMenu: '快速菜单',
            CheckMirror: '检查镜像'
        },
        Search: {
            NoResults: '没有结果',
            Create: '创建'
        },
        LoginRegister: {
            EmailAddress: '电子邮件地址',
            Password: '密码',
            Login: '登录',
            Register: '登记',
            FullName: '全名',
            FullNamePlaceholder: '晓明',
            CommunitySearch: '社区搜索',
            CommunitySearchPlaceholder: '搜索社区',
            CheckAvailability: '检查可用性',
            AccountType: '我是',
            Uppercase: '大写字母',
            Lowercase: '小写字母',
            Number: '数字',
            SpecialCharacter: '特殊字符',
            MinimumCharacters: '最少字符',
            RestoringSession: '恢复会话...',
            LogInToGravity: '登录Bright Village',
            OrAreYouNew: '新用户',
            ForgotPassword: '忘记密码？',
            Request: '要求',
            GoBack: '回去',
            RequestSent: '请求已发送',
            Continue: '继续'
        },
        Package: {
            Basic: '基本',
            RecordAndShare: '录制+分享',
            Free: '免费',
            Includes: '包括',
            ThirtyMin: '30分钟共享录制限制',
            Community: '社区',
            Folders: '文件夹',
            GetStarted: '开始',
            Premium: '高级',
            Unlimited: '无限',
            UnlimitedEverything: '无限一切',
            AIWorkflows: 'AI工作流',
            PerMonth: '每月',
            EverythingInBasicPlus: '基本功能加上',
            UnlimitedRecordingLimit: '无限录制限制',
            UnlimitedCommunities: '无限社区',
            UnlimitedFolders: '无限文件夹',
            AIVoiceToneAnalysis: 'AI语音音调分析',
            AIAutoTranscription: 'AI自动转录',
            AIKeywordsAndInsights: 'AI关键字和见解',
            StartNow: '现在开始',
            Enterprise: '企业',
            AdminAndAnalytics: '管理+分析',
            Custom: '习惯',
            EverythingInPremiumPlus: '高级版中的所有内容，加上',
            AnalyticsAndUsage: '分析和使用情况',
            DocumentAttachments: '文档附件',
            DocumentAttachmentsDescription: '附加文档和Youtube视频以提供更多上下文！',
            MonthlyEngagementReports: '每月参与报告',
            DedicatedAccountManager: '专属客户经理',
            TwentyFourSevenSupport: '24/7支持',
            LetsTalk: '让我们谈谈',
            OneMinuteRecordingLimit: '1分钟录制限制',
            UnlimitedMagicAIPrompts: '无限Bright AI提示',
            UnlimitedMagicAIPromptsDescription: 'Bright Village提示通过单个录音创建整个课程',
            UnlimitedRecordingLimitDescription: '无限制地录制任何内容',
            AIVoiceToneAnalysisDescription: '即时洞察语气、情绪和原创性',
            AIAutoTranscriptionDescription: '提高可访问性、参与度和学习成果',
            AIKeywordsAndInsightsDescription: '关键字提取、即时定义和建议内容',
            AnalyticsAndUsageDescription: '详细的报告使用情况、参与度、什么有效、什么无效'
        },
        Menu: {
            Select: '选择',
            Create: '创建',
            CommunityBegin: '一个社区开始.',
            Communities: '社区',
            Folders: '文件夹',

            AddCommunity: '添加社区',
            AddCommunityDescription: '加入或建立下一个供人们协作、交谈和探索下一步的好地方。 使用 Bright Village 将人们带到同一张桌子旁。',
            AddCommunityName: '社区名字',
            AddCommunityPlaceholder: '例如: DEJ073',
            CreateCommunityPlaceholder: '例如: 桑树街学校',

            AddJoinCode: '输入加入密码',
            AddJoinCodeDescription: '加入您的同学社区进行协作、交谈并探索下一步.',
            AddJoinCodeName: '加入代码',
            AddJoinCodePlaceholder: '例如: 0XF78D',

            CreateFolder: '创建一个文件夹',
            CreateFolderDescription: '将社区中的人们聚集到集中的小组中讨论任何事情。 建立协作对话、投票等。',
            CreateFolderName: '文件夹名称',
            CreateFolderPlaceholder: '例如: 欧洲历史课',

            ConfirmDelete: '删除文件夹',
            ConfirmDeleteDescription: '你确定你要删除',
            ConfirmDeleteDiscussion: '删除讨论',

            CreateFolderStart: '创建文件夹以开始协作',
            Duplicate: '复制',
            ConfirmDuplicate: '重复文件夹',
            ConfirmDuplicateDescription: '将在没有响应的情况下重复。 请为新文件夹输入唯一的名称。',
            UniqueName: '独特的名字',
        },
        Modal: {
            Cancel: '取消',
            Submit: '提交',
            Loading: '加载中',
            Uploading: '上传',
            Close: '关闭',
            ShortDescription: '提示描述(必需的)',
            ShortDescriptionPlaceholder: '例如: 请讲两分钟关于...',
            SpaceType: '空间类型',
            Conversation: '对话',
            ConversationDescription: '对话让您可以随时记录、回复和与同学协作。',
            Poll: '轮询',
            PollDescription: '准备好权衡选择或达成共识了吗？ 发起民意调查，让社区发言。',
            VideoRecording: '视频录制',
            AttachFile: '附加文件',
            YoutubeLink: 'YouTube网址',
            ViewPreview: '查看预览',
            ClosePreview: '关闭预览',

            ChangeDescription: '更改您的全名或个人资料图片。',
            ProfileImage: '个人资料图片',
            ChangeHandle: '@ 名字',
            ChangeHandlePlaceholder: '名字 (没有@, 没有空间)',

            JoinByCode: '加入代码',
            CreateNew: '创建新的',
            RelatedVideos: '有关的影片',
            RelatedArticles: '有关的文章',
            NoResults: '没有结果',

            PremiumTitle: '升级到高级版',
            PremiumDescription: '仅需 $8,即可解锁最强大的 AI 工作流工具，可节省您数小时的工作时间，通过自动建议创建整个课程，并大大提高参与度',
            Upgrade: '25 个座位 - $8 / 月',
            UpgradeYearly: '无限座位 - $82 / 年',
            YouArePremium: '您是高级版',
            PremiumThankYou: '感谢您成为',
            Monthly: '每月',
            Yearly: '每年',
            PremiumMember: 'Bright Village高级会员。',
            NextRenewal: '下次续订',
            Savings: '15% 折扣 + 奖励',
            ContactUs: '联系我们',

            Yay: '耶!',
            Space: '房间',
            Response: '空间',
            Created: '创建!',
            OnYourWay: '您现在正在进行令人兴奋的协作!',
            Return: '返回',

            AIPromptAssistant: 'AI提示助手',
            PromptScript: '提示脚本',
            PromptScriptPlaceholder: '在这里粘贴您的脚本。 此区域不会被记录。',
            Hide: '隐藏',
            Show: '显示',
            Update: '更新',
            Private: '私人'
        },
        Content: {
            JoinCode: '加入代码',
            Welcome: '欢迎',
            NoUpdates: '自您上次登录以来没有更新',
            CreateASpace: '创造新空间',

            CreateNewPrompt: '创建新提示',
            CreateSpaceDescription: '录制您的面部、声音或屏幕，然后开始工作。 添加信息以帮助其他人协作!',
            CreateSpaceName: '提示标题(必需的)',
            CreateSpacePlaceholder: '例如: 启蒙时代的探讨',
            ResponseTitle: '回复标题(必需的)', 

            NoPromptsYet: '还没有提示',
            SelectAFolder: '选择一个文件夹',

            Back: '返回'
        },
        Recorder: {
            Video: '视频',
            Screen: '屏幕',
            Voice: '语音',
            Resume: '恢复',
            Pause: '暂停',
            Recording: '记录的',

            Originality: '独创性',
            Tone: '笔调',
            Neutral: '中性',
            Positive: '正面',
            Negative: '负面',

            InfoHover: 'Bright Village利用技术来检测生成AI的响应,例如ChatGPT. (这是一个实验性功能)',
            ToneHover: 'Bright Village 利用技术帮助检测基于视频转录的音调. (这是一个实验性功能)'
        },
        SpacesView: {
            Conversation: '对话',
            Poll: '轮询',
            Members: '成员',
            Joined: '已加入',
            Anonymous: '匿名'
        },
        AssetsView: {
            Teacher: '老师',
            Student: '学生',
            Respond: '回复',
            To: '',
            RespondTo: '回复',
            DocumentAttachment: '文件附件',
            YoutubeAttachment: 'YouTube附件',
            NoResponses: '还没有回复',
            View: '查看',
            Reply: '回复',
            Replies: '个回复',

            ResponseDescriptionPlaceholder: '例如: 在我们做出决定之前...',
            SidebarWith: '侧边栏',
            ThreadDescriptionPlaceholder: '例如: 你能把这个细节扩展一下吗...',

            Back: '返回',
            Keywords: '关键词',
            Insights: '更多信息',
            EditKeywords: '编辑关键词',
            EditKeywordsDescription: '请保留至少5个关键字',
        },
        ThreadView: {
            Collapse: '关闭',
            Back: '返回提示'
        },
        Upload: {
            DropFileHere: '把文件放在这里',
            Or: '或',
            SelectFile: '选择文件',
            FileName: '文件名',
            FileType: '文件类型',
            Reselect: '重新选择'
        },
        Video: {
            Transcripts: '*记录是自动生成的。 可能会出现不准确的情况。'
        }
    },
    nl: {
        Marketing: {
            StartFree: 'Start Gratis',
            LogIn: 'Log In',
            HeroTitle: 'Ontwikkel uw leren en ontwikkeling',
            HeroDescriptionBold: 'Een geïntegreerd asynchroon video-leren en samenwerkingsplatform',
            HeroDescription: 'Aangedreven door echte interactie en directe feedback.',
            AIAssistedDiscussions: 'Maak vanaf elke locatie',
            AIAssistedDiscussionsDescription: 'Neem direct videolessen op vanaf uw computer, voeg inhoud toe, wijs opgenomen reacties toe en meet feedback. Krijg inzicht in begrip, acceptatie van ideeën, werknemersmorale en meer.',
            KeywordsInsights: 'Bespaar tijd en geld',
            KeywordsInsightsDescription: 'Unieke AI spraakherkenning en trefwoord/zinsdetectie tonen echt begrip van de inhoud aan. Belangrijker nog, het bouwt een uitgebreide statistiek op, zodat uw afdeling weet dat uw initiatieven daadwerkelijk werken - tijd, middelen en dure fouten besparen.',
            DifferentiatedLearning: 'Differentiated Learning',
            DifferentiatedLearningDescription: 'Bright Village stelt u in staat om een unieke weg te banen voor de leerstijl van de leerlingen, veranderende onderwijsstrategieën en biedt meerdere manieren om begrip uit te drukken.',
            CallOut: 'Bouw een modern L&D-programma dat niet alleen over leren gaat, maar over krachtige groei en gezamenlijke groei.',
            ThoughtEquity: 'Echte succes',
            ThoughtEquityDescription: 'Sla dia\'s over, spoel video\'s door, gok met multiple choice. Ga recht op de kern van de zaak af, maak verbinding met anderen, en leg de stukjes bij elkaar. Bright Village zorgt ervoor dat uw werknemers de lat hoger leggen en de waarden van succes in de praktijk brengen.',
            
            ResearchBacked: 'Onderzoeksondersteuning',
            ResearchBackedDescription: 'Bright Village is geselecteerd voor kwantitatief onderzoek door de School of Education van de Johns Hopkins University en heeft aangetoond dat het de leerresultaten aanzienlijk verbetert.',
            ByEducators: 'Gebouwd door de besten',
            ByEducatorsDescription: 'Bright Village is gebouwd door een team van professoren, Fortune 500-leiders en technische experts die geloven dat echte video-gebaseerde verbindingen de wereld van L&D kunnen veranderen.',
            
            MagicAIPrompts: 'AI Prompts',
            UnlimitedRecording: 'Onbeperkt opnemen',
            AIVoiceToneAnalysis: 'Directe inzichten in toon, sentiment en originaliteit',
            ReadyToSupercharge: 'Klaar om uw leren en ontwikkeling te laten groeien?',
            YesIAm: 'Ja, ik ben - laten we beginnen!',
            
            StartForFree: 'Start Gratis',
            StartForFreeDescription: 'Uw werknemers verdienen het!',
            StartForFreeCallout: 'Geen creditcard nodig',
            AffordablePricing: 'Betaalbare prijzen',
            AffordablePricingDescription: 'Slechts $8 / maand of $76 / jaar',
            AffordablePricingCallout: '20% korting op jaarbasis'
        },
        Header: {
            Logout: 'Uitloggen',
            EditProfile: 'Bewerk profiel',
            MonthlyRecordingLimit: 'Maandelijkse opnamelimiet',
            MinutesRemaining: 'Minuten over'
        },
        Register: {
            Greeting: 'Hallo',
            GetRegistered: 'Laten we ons registreren',
            HaveJoinCode: 'Ik heb een join-code',
            CreateCommunity: 'Maak een nieuwe community',
            JoinCode: 'Join Code',
            NewCommunity: 'Nieuwe community',
            SixCode: 'Voer uw 6-cijferige code in',
            CommunityName: 'Hoe wilt u uw community noemen?',
            CreateAccount: 'Account aanmaken',
            ToJoin: 'om mee te doen ',
            ThisCommunity: 'deze community',
            ToStart: 'start ',
            YourCommunity: 'jouw community',
            Now: ' nu'
        },
        Dashboard: {
            BookDemo: 'Boek GRATIS een live demo van 15 minuten van Bright Village!',
            Dashboard: 'Dashboard',
            Watched: 'Bekeken',
            Videos: 'Videos',
            Created: 'Gemaakt',
            Recordings: 'Opnames',
            Responded: 'Gereageerd',
            Conversations: 'Gesprekken',
            CreatorStudio: 'Creator Studio',
            QuickPrompt: 'Snelle prompt',
            MagicAIPrompt: 'Bright AI Prompt',
            MagicAI: 'Bright AI',
            Left: 'Over',
            Unlock: 'Ontgrendelen',
            RecentVideos: 'Recente videos',
            Engagement: 'Betrokkenheid',
            QuickMenu: 'Snelle menu',
            CheckMirror: 'Controleer spiegel'
        },
        Search: {
            NoResults: 'Geen resultaten',
            Create: 'Creëren'
        },
        LoginRegister: {
            EmailAddress: 'E-mailadres',
            Password: 'Wachtwoord',
            Login: 'Log in',
            Register: 'Register',
            FullName: 'Voor-en Achternaam',
            FullNamePlaceholder: 'Julia Jansen',
            CommunitySearch: 'Community Zoeken',
            CommunitySearchPlaceholder: 'Zoeken naar gemeenschap',
            CheckAvailability: 'Beschikbaarheid Controleren',
            AccountType: 'Ik ben een',
            Uppercase: 'Hoofdletter',
            Lowercase: 'Kleine Letter',
            Number: 'Nummer',
            SpecialCharacter: 'Speciaal Karakter',
            MinimumCharacters: 'Minimale Tekens',
            RestoringSession: 'Sessie herstellen...',
            LogInToGravity: 'Inloggen op Bright Village',
            OrAreYouNew: 'Of ben je nieuw?',
            ForgotPassword: 'Wachtwoord vergeten?',
            Request: 'Verzoek',
            GoBack: 'Ga terug',
            RequestSent: 'Verzoek verzonden',
            Continue: 'Doorgaan'
        },
        Package: {
            Basic: 'Basis',
            RecordAndShare: 'Opnemen + delen',
            Free: 'Vrij',
            Includes: 'Inclusief',
            ThirtyMin: 'Gedeelde opnamelimiet van 30 minuten',
            Community: 'Gemeenschap',
            Folders: 'Mappen',
            GetStarted: 'Aan de slag',
            Premium: 'Premium',
            Unlimited: 'Onbeperkt',
            UnlimitedEverything: 'Onbeperkt alles',
            AIWorkflows: 'AI Workflows',
            PerMonth: 'per maand',
            EverythingInBasicPlus: 'Alles in Basic, plus',
            UnlimitedRecordingLimit: 'Onbeperkte opnamelimiet',
            UnlimitedCommunities: 'Onbeperkte gemeenschappen',
            UnlimitedFolders: 'Onbeperkte mappen',
            AIVoiceToneAnalysis: 'AI Stemtoonanalyse',
            AIAutoTranscription: 'AI Automatische transcriptie',
            AIKeywordsAndInsights: 'AI Trefwoorden en inzichten',
            StartNow: 'Begin nu',
            Enterprise: 'Onderneming',
            AdminAndAnalytics: 'Beheer + analyse',
            Custom: 'Gewoonte',
            EverythingInPremiumPlus: 'Alles in Premium, plus',
            AnalyticsAndUsage: 'Analyse en gebruik',
            DocumentAttachments: 'Document Video Bijlagen',
            DocumentAttachmentsDescription: 'Voeg documenten en Youtube-video\'s toe om extra context te bieden!',
            MonthlyEngagementReports: 'Maandelijkse betrokkenheidsrapporten',
            DedicatedAccountManager: 'Toegewijde accountmanager',
            TwentyFourSevenSupport: '24/7 Ondersteuning',
            LetsTalk: 'Laten we praten',
            OneMinuteRecordingLimit: '1 minuut opnamelimiet',
            UnlimitedMagicAIPrompts: 'Onbeperkte Bright AI Prompts',
            UnlimitedMagicAIPromptsDescription: 'BV Prompts creëren hele lessen met een enkele opname',
            UnlimitedRecordingLimitDescription: 'Neem zoveel op als je wilt zonder beperkingen',
            AIVoiceToneAnalysisDescription: 'Direct inzicht in toon, sentiment en originaliteit tegen ChatGPT',
            AIAutoTranscriptionDescription: 'Verhoog de toegankelijkheid, betrokkenheid en leerresultaten',
            AIKeywordsAndInsightsDescription: 'Trefwoorden extractie, directe definities en voorgestelde inhoud',
            AnalyticsAndUsageDescription: 'Gedetailleerde rapporten gebruik, betrokkenheid, wat werkt en wat niet'
        },
        Menu: {
            Select: 'Selecteer',
            Create: 'Maak',
            CommunityBegin: 'een community om te beginnen.',
            Communities: 'Gemeenschappen',
            Folders: 'Mappen',

            AddCommunity: 'Community Toevoegen',
            AddCommunityDescription: 'Doe mee of bouw de volgende geweldige plek waar mensen kunnen samenwerken, praten en ontdekken wat de toekomst biedt. Breng mensen rond dezelfde tafel met Bright Village.',
            AddCommunityName: 'Gemeenschaps Naam',
            AddCommunityPlaceholder: 'BV. DEJ073',
            CreateCommunityPlaceholder: 'BV. Mulberry Street School',

            AddJoinCode: 'Voer de deelnamecode in',
            AddJoinCodeDescription: 'Sluit je aan bij de community van je klasgenoten om samen te werken, te praten en te ontdekken wat de toekomst biedt.',
            AddJoinCodeName: 'Lid worden van de code',
            AddJoinCodePlaceholder: 'BV. 0XF78D',

            CreateFolder: 'Een map maken',
            CreateFolderDescription: 'Verzamel mensen in uw gemeenschap in gerichte groepen om alles te bespreken. Bouw gezamenlijke gesprekken, peilingen en meer.',
            CreateFolderName: 'Mapnaam',
            CreateFolderPlaceholder: 'BV. Europese geschiedenisles',

            ConfirmDelete: 'Verwijder map',
            ConfirmDeleteDescription: 'Weet je zeker dat je wilt verwijderen',
            ConfirmDeleteDiscussion: 'Discussie verwijderen',

            CreateFolderStart: 'Maak een map om samen te werken',
            Duplicate: 'Duplicaat',
            ConfirmDuplicate: 'Dubbele map',
            ConfirmDuplicateDescription: 'wordt gedupliceerd zonder de antwoorden. Voer een unieke naam in voor de nieuwe map.',
            UniqueName: 'Unieke naam',
        },
        Modal: {
            Cancel: 'Annuleren',
            Submit: 'Indienen',
            Loading: 'Laden',
            Uploading: 'Uploaden',
            Close: 'Dichtbij',
            ShortDescription: 'Prompt Beschrijving (Vereist)',
            ShortDescriptionPlaceholder: 'BV. Spreek alstublieft 2 minuten over...',
            SpaceType: 'Ruimtetype',
            Conversation: 'Gesprek',
            ConversationDescription: 'Met gesprekken kun je op elk moment opnemen, reageren en samenwerken met klasgenoten.',
            Poll: 'Peiling',
            PollDescription: 'Klaar om opties af te wegen of consensus te bereiken? Start een poll en laat de community aan het woord.',
            VideoRecording: 'Video-opname',
            AttachFile: 'Bijlagen',
            YoutubeLink: 'YouTube-URL',
            ViewPreview: 'Voorbeeld sluiten',
            ClosePreview: 'voorbeeld bekijken',

            ChangeDescription: 'Wijzig uw volledige naam of uw profielafbeelding wijzigen.',
            ProfileImage: 'Profielafbeelding',
            ChangeHandle: '@ Naam',
            ChangeHandlePlaceholder: 'Naam (geen @, geen spaties)',

            JoinByCode: 'Doe mee met code',
            CreateNew: 'Maak nieuw',
            RelatedVideos: 'Gerelateerde videos',
            RelatedArticles: 'Gerelateerde artikelen',
            NoResults: 'Geen resultaten',

            PremiumTitle: 'Premium ontgrendelen',
            PremiumDescription: 'Voor slechts $8, ontgrendel de krachtigste AI-workflowtools die u uren werk besparen, hele lessen maken van uw prompts en de betrokkenheid een boost geven',
            Upgrade: '25 Seats - $8 / Maand',
            UpgradeYearly: 'Onbeperkte Seats - $82 / Jaar',
            YouArePremium: 'Je bent Premium',
            PremiumThankYou: 'bedankt dat je een',
            Monthly: 'Maandelijks',
            Yearly: 'Jaarlijks',
            PremiumMember: 'Bright Village Premium lid',
            NextRenewal: 'Volgende verlenging',
            Savings: '15% Korting + Bonus',
            ContactUs: 'Neem contact op',

            Yay: 'Yay!',
            Space: 'Ruimte',
            Response: 'Reactie',
            Created: 'Gemaakt!',
            OnYourWay: 'Je bent nu op weg naar spannende samenwerking!',
            Return: 'Terug',

            AIPromptAssistant: 'AI Prompt Assistant',
            PromptScript: 'Prompt Script',
            PromptScriptPlaceholder: 'Plak hier uw script. Dit gebied wordt niet opgenomen.',
            Hide: 'Verbergen',
            Show: 'Show',
            Update: 'Update',
            Private: 'Privaat'
        },
        Content: {
            JoinCode: 'Deelnamecode',
            Welcome: 'Welkom',
            NoUpdates: 'Geen updates sinds je laatste login',
            CreateASpace: 'Creëer Nieuwe Ruimte',

            CreateNewPrompt: 'Nieuwe prompt maken',
            CreateSpaceDescription: 'Neem je gezicht, stem of scherm op en ga aan de slag. Voeg informatie toe om anderen te helpen samenwerken!',
            CreateSpaceName: 'Prompt Titel (Vereist)',
            CreateSpacePlaceholder: 'BV. Discussie over het tijdperk van de Verlichting',
            ResponseTitle: 'Titel van antwoord (vereist)', 

            NoPromptsYet: 'Nog geen aanwijzingen',
            SelectAFolder: 'Selecteer een map',

            Back: 'Terug'
        },
        Recorder: {
            Video: 'Video',
            Screen: 'Scherm',
            Voice: 'Spraak',
            Resume: 'HERVAT',
            Pause: 'PAUZE',
            Recording: 'OPNEMEN',

            Originality: 'Originaliteit',
            Tone: 'Stijl',
            Neutral: 'Neutraal',
            Positive: 'Positief',
            Negative: 'Negatief',

            InfoHover: 'Bright Village maakt gebruik van technologie om reacties te detecteren door generatieve AI zoals ChatGPT. (Dit is een experimentele functie)',
            ToneHover: 'Bright Village maakt gebruik van technologie om toon te helpen detecteren op basis van videotranscriptie. (Dit is een experimentele functie)'
        },
        SpacesView: {
            Conversation: 'Gesprek',
            Poll: 'Peiling',
            Members: 'Leden',
            Joined: 'Toegetreden',
            Anonymous: 'Anoniem'
        },
        AssetsView: {
            Teacher: 'Docent',
            Student: 'Student',
            Respond: 'Reageer',
            To: ' op',
            RespondTo: 'Reageer op',
            DocumentAttachment: 'Documentbijlagen',
            YoutubeAttachment: 'YouTubebijlage',
            NoResponses: 'Nog geen amtwoorden',
            View: 'Bekijk',
            Reply: 'Antwoord',
            Replies: 'Antwoorden',

            ResponseDescriptionPlaceholder: 'BV. Voordat we een beslissing nemen...',
            SidebarWith: 'Zijbalk met',
            ThreadDescriptionPlaceholder: 'BV. Kun je dat detail een beetje uitbreiden...',

            Back: 'Terug naar',
            Keywords: 'Trefwoorden',
            Insights: 'Inzichten',
            EditKeywords: 'Bewerk trefwoorden',
            EditKeywordsDescription: 'Houd minimaal 5 trefwoorden aan',

        },
        ThreadView: {
            Collapse: 'Dichtbij',
            Back: 'Terug naar prompt'
        },
        Upload: {
            DropFileHere: 'Zet het bestand hier neer',
            Or: 'OF',
            SelectFile: 'Selecteer bestand',
            FileName: 'Bestandsnaam',
            FileType: 'Bestandstype',
            Reselect: 'Opnieuw selecteren'
        },
        Video: {
            Transcripts: '*Transcripties worden automatisch gegenereerd. Er kunnen onjuistheden voorkomen.'
        }
    }
};
  
export default strings;
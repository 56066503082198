import { useContext, useState, useRef, useEffect } from 'react';
import { Context } from '../../DataStore';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/common/Video.scss';

const TranscriptIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/transcript.svg`;
const VoiceIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/voice.svg`;
const openIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/open.svg`;

const Video = (props) => {
  const { store } = useContext(Context);

  const [showTranscript, setShowTranscript] = useState(false);
  const initTranscript = useRef(false);

  const [videoSrc, setVideoSrc] = useState('');
  const videoElement = useRef(null);

  const transcriptControl = () => {
    if (!initTranscript.current) {
      initTranscript.current = true;
    }
    setShowTranscript(!showTranscript);
  }

  const handlePlay = () => {
    if (!videoSrc.length) {
      setVideoSrc(constants.services.s3ToCloudfrontMedia(props.setSrc));
    }
  }

  useEffect(() => {
    const playVideo = () => {
      if (videoElement.current) {
        videoElement.current.play();
      }
    };

    if (videoElement.current && videoSrc.length) {
      videoElement.current.addEventListener('loadeddata', playVideo);
    }
  }, [videoSrc]);


  return (
    <div
      className={`Video ${props.level} ${props.level === 'main' ? (showTranscript ? ' transcript' : ' noTranscript') : ''}`}
      style={props.width !== undefined ? { width: `${props.width}px` } : {}}>
      <video
        className={`videoPlayer transcript${showTranscript ? 'Open' : ' Closed'}${props.type === 'voice' ? ' voice' : ''}${props.level === 'main' ? ' main' : ''}`}
        style={props.width !== undefined ? { width: `${props.width}px` } : {}}
        ref={videoElement}
        src={(props.src !== undefined && props.src.length) ? props.src : videoSrc}
        poster={''}
        onPlay={(e) => props.onPlay !== undefined ? props.onPlay(e) : null}
        playsInline
        controls />
      <div className={`posterBackground${!videoSrc.length && props.setPoster !== undefined ? ' active' : ''}`}>
        <div
          className="posterImage"
          style={{ backgroundImage: `url(${props.setPoster !== undefined ? `${constants.services.awsLocation(true)}/${props.setPoster}` : ''})` }} />
        {props.type === 'voice' &&
          <img
            className="voiceIcon"
            src={VoiceIcon}
            alt="Voice Icon" />
        }
        <button
          className="overlayPlayButton"
          disabled={videoSrc.length && props.setPoster !== undefined}
          onClick={() => handlePlay()}>
          <div className="openImage">
            <img
              className="openIcon"
              src={openIcon}
              alt="Open video" />
          </div>
        </button>
      </div>

      {(props.type === 'voice' && videoSrc.length) &&
        <img
          className="voiceIcon"
          src={VoiceIcon}
          alt="Voice Icon" />
      }

      {store.language === 'en' && props.transcript !== undefined && props.transcript !== 0 && props.transcript !== '' &&
        <>
          <button
            className={`transcriptButton${showTranscript ? ' open' : ''}`}
            onClick={() => transcriptControl()}>
            <img
              className="transcriptIcon"
              src={TranscriptIcon}
              alt="Transcript" />
          </button>
          <div className={`transcriptWrapper${showTranscript ? ' open' : initTranscript.current ? ' closed' : ''}`}>
            <div className="transcript">
              <div className="transcriptText">
                {props.transcript}
                <div className="transcriptNote">
                  {strings.default[store.language].Video.Transcripts}
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default Video;

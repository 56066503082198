export const devMode = (window.location.hostname === 'localhost');


// App URLs
export const url = {
  base: 'https://brightvillage.org',
  get api() {
    return (devMode ? 'http://localhost:8000' : 'https://usegravity.herokuapp.com')
  }
};

// NOTE: Has direct dev s3 endpoint for testing
export const awsLocation = (forceProd) => {
  return `https://${devMode ? (forceProd ? 'dmn09ay7q1luk.cloudfront.net' : 'dev-gravity-media.s3.amazonaws.com') : 'dmn09ay7q1luk.cloudfront.net'}`;
}

// Fetch templates
export const config = (data, type) => (
  {
    method: (type ?? 'POST'),
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }
);

export const getConfig = (data) => (
  {
    method: 'GET',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }
);

export const s3ToCloudfrontMedia = (url) => {
  return url.replace('https://gravity-media.s3.amazonaws.com/', 'https://dmn09ay7q1luk.cloudfront.net/');
};

export const endSession = () => {
  if (localStorage.getItem('bvSession') !== null) {
    localStorage.removeItem('bvSession');
  }
}
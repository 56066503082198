export const isMobile = () => {
  return (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
} 
export const isSafari = () => {
  return navigator.userAgent.indexOf("Chrome") < 0 && navigator.userAgent.indexOf("Safari") > -1;
} 

export const randomID = () => {
  const randomLetter = ('abcdefghijklmnopqrstuvwxyz').split('')[(Math.floor(Math.random() * 26))];
  const randomUUID = parseInt(Math.random() * 1000000000);
  return `${randomLetter}${randomUUID}`;
}

export const isObject = (obj) => {
  return !(Object.keys(obj).length === 0 && obj.constructor === Object);
}

export const capitalizeFirst = (word) => {
  return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
}

export const lowercaseFirst = (word) => {
  return `${word.charAt(0).toLowerCase()}${word.slice(1)}`;
}

export const truncateString = (str, num) => {
  if (str.length <= num) return str;
  return str.slice(0, num) + '...'
}

export const dateTime = () => {
  const currentDate = new Date();
  return `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()} ${currentDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
}

export const parseDate = (creation) => {
  const dateArray = creation.split('-');
    const dataArray = dateArray[2].split('T');
  return `${parseInt(dateArray[1])}/${parseInt(dataArray[0])}/${dateArray[0]}`
}

export const parseMonthYear = (creation) => {
  return new Date(creation).toLocaleString('en-us', { month: 'long', year: 'numeric' });
}

export const parseDateTime = (creation) => {
  const dateArray = creation.split('-');
    const dataArray = dateArray[2].split('T');
      const timeArray = dataArray[1].split(':');
        const isMidnight = timeArray[0] === 0;
        const isMidday = timeArray[0] === 12;
        const isPM = timeArray[0] > 12;
  return `${parseInt(dateArray[1])}/${parseInt(dataArray[0])}/${dateArray[0]} at ${isMidnight ? '12' : isPM ? (timeArray[0] - 12) : timeArray[0]}:${timeArray[1]} ${(isMidday || isPM) ? 'PM' : 'AM'}`
}

export const cleanText = (string) => {
  return (string.trim()).replace('&nbsp;', ' ').replace(/ +/g, ' ');
}

export const placeCaretAtEnd = (element) => {
  element.focus();

  if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
    let range = document.createRange();
    range.selectNodeContents(element);
    range.collapse(false);
    let sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange !== 'undefined') {
    let textRange = document.body.createTextRange();
    textRange.moveToElementText(element);
    textRange.collapse(false);
    textRange.select();
  }
}

export const getCaretPosition = (element) => {
  var caretPos = 0,
    sel, range;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.rangeCount) {
      range = sel.getRangeAt(0);
      if (range.commonAncestorContainer.parentNode === element) {
        caretPos = range.endOffset;
      }
    }
  } else if (document.selection && document.selection.createRange) {
    range = document.selection.createRange();
    if (range.parentElement() === element) {
      var tempEl = document.createElement("span");
      element.insertBefore(tempEl, element.firstChild);
      var tempRange = range.duplicate();
      tempRange.moveToElementText(tempEl);
      tempRange.setEndPoint("EndToEnd", range);
      caretPos = tempRange.text.length;
    }
  }
  return caretPos;
}

export const stripFileName = (fileName) => {
  let fileNameArr = fileName.split('.');
  if (fileNameArr.length > 1) {
    fileNameArr.pop();
    return fileNameArr.join('');
  } else {
    return fileName;
  }
}

export const arraysAreEqual = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

export const monthMapping = (index) => {
    const map = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ]
    return map[index];
}
export const currentMonth = new Date().getMonth();
export const currentYear = new Date().getFullYear();

export const generateRandomPassword = () => {
  const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const upperCaseLetters = lowerCaseLetters.toUpperCase();
  const numbers = '0123456789';
  const specialCharacters = '!@#$%^&*';
  const allCharacters = lowerCaseLetters + upperCaseLetters + numbers + specialCharacters;
  
  let randomString = '';
  randomString += upperCaseLetters[Math.floor(Math.random() * upperCaseLetters.length)];
  randomString += numbers[Math.floor(Math.random() * numbers.length)];
  randomString += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

  for (let i = randomString.length; i < 20; i++) {
      randomString += allCharacters[Math.floor(Math.random() * allCharacters.length)];
  }

  return randomString.split('').sort(() => 0.5 - Math.random()).join('');
}
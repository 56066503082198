import { useContext, useEffect, useRef } from 'react';
import { Context } from '../../DataStore';
import { useHistory, useParams } from "react-router-dom";
import * as schools from '../../data/schools';

import ReactGA from 'react-ga4';
import Hero from './Hero';
import ContentSection from './ContentSection';
import CallOut from './CallOut';
import Features from './Features';
import WhatIs from './WhatIs';
import CTASection from './CTASection';

import '../../styles/marketing/Marketing.scss';

const Marketing = () => {
  const { store, dispatch } = useContext(Context);
  const params = useParams();
  const history = useHistory();

  const fetchedSchool = useRef(false);

  const schoolFound = (params.school !== undefined && schools.schools.indexOf(params.school) > -1);
  const schoolMascot = (schoolFound ? `${schools.schooolData[params.school].mascot}` : 'people');
  const schoolName = (schoolFound ? ` at ${schools.schooolData[params.school].name}` : '');
  const schoolSector = (schoolFound ? `-${schools.schooolData[params.school].sector}` : '');

  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "Marketing"
  });

  useEffect(() => {
    if (!fetchedSchool.current) {
      fetchedSchool.current = true;

      if (schoolFound) {
        dispatch({
          type: 'marketing',
          data: {
            ...store.marketing,
            school: params.school
          }
        });
      } else {
        history.push('/');
      }
    }
  }, [dispatch, history, params.school, store.marketing, schoolFound]);

  return (
    <div className="Marketing">
      <Hero
        schoolMascot={schoolMascot}
        schoolName={schoolName}
        sector={schoolSector} />
      <ContentSection
        sector={schoolSector} />
      <CallOut />
      <Features />
      <WhatIs />
      <CTASection />
    </div>
  );
}

export default Marketing;

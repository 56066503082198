import ReactGA from 'react-ga4';
import { useContext, useState, useCallback, useEffect } from 'react';
import { Context } from '../../DataStore';
import { useHistory, useLocation } from "react-router-dom";

import { GoogleOAuthProvider } from '@react-oauth/google';
import Google from '../common/Google';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import Header from '../common/Header';

import '../../styles/composite/Login.scss';

const RestoreRobot = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/restore-robot.svg`;
const LoadingIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/loading.svg`;

const Login = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/login/",
    title: "Login"
  });

  const { store, dispatch } = useContext(Context);
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [reset, setReset] = useState(false);
  const [requestSent, setRequestSent] = useState(false);

  const [restoring, setRestoring] = useState(false);
  const [restoreAttempted, setRestoreAttempted] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const sendMessage = useCallback((data) => {
    const statusCopy = {
      ...store.status,
      message: {
        type: data.type,
        text: data.text
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }, [dispatch, store.status]);

  const submitForm = async (socialLogin) => {

    const completeLogin = (responseData) => {
      localStorage.setItem('bvSession', JSON.stringify(responseData.session));

      const storeCopy = {
        ...store,
        profile: responseData.user,
        session: responseData.session,
        subscription: responseData.subscription
      }
      dispatch({
        type: 'store',
        data: storeCopy
      });

      history.push('/dashboard/');
    }

    const attemptLogin = async () => {
      try {
        let data = {
          name: '',
          email: email,
          password: password,
          role: '',
          cid: null,
          community: null
        }

        // UI effected
        setSubmitting(true);

        const url = `${constants.services.url.api}/user/login/`;
        const response = await fetch(url, constants.services.config(data));
        const responseData = await response.json();

        setSubmitting(false);

        if (response.ok) {
          if (responseData.status === 'Success') {
            completeLogin(responseData);
          } else {
            sendMessage({ type: 'warning', text: responseData.status });
          }
        } else {
          sendMessage(constants.strings.messages('error', 'network'));
        }
      } catch (error) {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    }

    if (socialLogin !== undefined && socialLogin) {
      completeLogin(socialLogin);
    } else {
      attemptLogin();
    }
  }

  const restoreSession = useCallback(async (localSession) => {
    setRestoring(true);

    try {
      const url = `${constants.services.url.api}/user/restore/`;
      const response = await fetch(url, constants.services.config(localSession));
      const responseData = await response.json();

      setRestoring(false);

      if (response.ok) {
        if (responseData.status === 'Success') {
          localStorage.setItem('bvSession', JSON.stringify(responseData.session));

          const storeCopy = {
            ...store,
            profile: responseData.user,
            session: responseData.session,
            subscription: responseData.subscription
          }
          dispatch({
            type: 'store',
            data: storeCopy
          });
          history.push(`/dashboard/${store.deeplink.premium ? 'premium/' : ''}`);
        } else {
          sendMessage(constants.strings.messages('error', responseData.status));
        }
      } else {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    } catch (error) {
      setRestoring(false);
      sendMessage(constants.strings.messages('error', 'network'));
    }
  }, [dispatch, store, sendMessage, history]);

  const passwordKeyUp = (event) => {
    if (event.keyCode === 13) {
      if (constants.validate.email(email) && constants.validate.password(password)) {
        submitForm();
      }
    }
  }

  const resetPasswordManager = () => {
    if (!reset) {
      ReactGA.event({
        category: "Login",
        action: "Visit - Reset Password"
      });
    }
    setReset(true);
  }

  const submitReset = async () => {
    try {
      const data = {
        email: email
      }
      const url = `${constants.services.url.api}/manage/password/forgot/`
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      if (response.ok) {
        if (responseData.status === 'Success') {
          setRequestSent(true);
        } else {
          sendMessage(constants.strings.messages('error', 'network'));
        }
      } else {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    } catch (error) {
      sendMessage(constants.strings.messages('error', 'network'));
    }
  }

  const backToLogin = () => {
    setReset(false);
    setRequestSent(false);
  }

  useEffect(() => {
    if (location.pathname === '/') {
      history.push('/login/');
    }
    if (!constants.utils.isObject(store.profile) && !restoring && !restoreAttempted) {
      setRestoreAttempted(true);
      if (localStorage.getItem('bvSession') !== undefined) {
        const localSession = JSON.parse(localStorage.getItem('bvSession'));
        if (localSession !== null) {
          restoreSession(localSession);
        };
      }
    }
  }, [restoreAttempted, restoreSession, restoring, store.profile, location.pathname, history]);

  return (
    <div className="Login">
      <Header />
      <div className="wrapper">
        <div className="loginBox">
          <div className="loginBackground">
            <div className="loginSide loginLeft">
              <div className="credentials">
                {/* Bright Village logo */}
                <div className="logo">
                  <div className="logoWrapper">
                    <img
                      className="icon"
                      src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}png/bright-village.png`}
                      alt="Bright Village logo" />
                  </div>
                </div>
                {restoring ?
                  <div className="restoring">
                    <div className="image">
                      <img
                        className="restoringImage"
                        src={RestoreRobot}
                        alt="Restoring session" />
                    </div>
                    <div className="text">
                      {strings.default[store.language].LoginRegister.RestoringSession}
                    </div>
                  </div> :
                  <div className="loginContainer">
                    <div className="login">
                      <div className="loginMessage">
                        {strings.default[store.language].LoginRegister[reset ? (requestSent ? 'RequestSent' : 'ForgotPassword') : 'LogInToGravity']}
                      </div>
                      {requestSent ?
                        <div className="resetText">
                          If this account exists, we will send you an email with reset instructions.
                        </div> :
                        <div className="inputs">
                          <input
                            className="input email"
                            type="email"
                            onChange={(event) => setEmail(event.target.value)}
                            placeholder={strings.default[store.language].LoginRegister.EmailAddress}
                            value={email} />
                        </div>
                      }
                      {reset ? '' :
                        <div className="inputs">
                          <input
                            className="input password"
                            type="password"
                            onChange={(event) => setPassword(event.target.value)}
                            onKeyUp={(event) => passwordKeyUp(event)}
                            placeholder={strings.default[store.language].LoginRegister.Password}
                            value={password} />
                        </div>
                      }
                    </div>

                    {requestSent ? '' :
                      <div className="resetWrapper">
                        <button
                          className={`resetPassword${reset ? ' reset' : ''}`}
                          onClick={() => (reset ? setReset(false) : resetPasswordManager())}>
                          {strings.default[store.language].LoginRegister[reset ? 'GoBack' : 'ForgotPassword']}
                        </button>
                      </div>
                    }

                    <div className="submitWrapper">
                      {!reset &&
                      <GoogleOAuthProvider clientId={constants.strings.googleClientID}>
                        <Google 
                          source="Login"
                          loginSuccess={(responseData) => submitForm(responseData)} />
                      </GoogleOAuthProvider>
                      }
                      <button
                        className="submit"
                        disabled={submitting || !email.length || !password.length || false}
                        onClick={() => reset ? (requestSent ? backToLogin() : submitReset()) : submitForm()}>
                        {strings.default[store.language].LoginRegister[reset ? (requestSent ? 'GoBack' : 'Request') : 'Login']}
                      </button>
                      {submitting &&
                        <img
                          className="loadingIcon"
                          src={LoadingIcon}
                          alt="Loading" />
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="loginSide loginRight">
              <div className="loginBackground">
                <img
                  className="loginImage"
                  src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}jpg/login.jpg`}
                  alt="Hello Bright Village users" />
              </div>
              <div className="registerWrapper">
                <div className="registerText">
                  {strings.default[store.language].LoginRegister.OrAreYouNew}
                </div>
                <button
                  className="registerButton"
                  onClick={() => history.push('/register/')}>
                  {strings.default[store.language].LoginRegister.Register}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

import Header from '../common/Header';

import '../../styles/content/Privacy.scss';

const Privacy = () => {
    return (
        <div className="Privacy">
            <Header />
            <div className="header">
                <span className="title">Privacy Policy</span>
            </div>
            <div className="content">
                <span className="title">Effective Date: November 19, 2023</span><br/>
                <br/>
                <span className="title">1. Introduction</span><br/>
                <li>Welcome to Bright Village, the asynchronous, AI-powered video communication platform designed for education and beyond.</li><br/>
                <li>Our commitment is to protect and respect your privacy. This policy describes the collection, use, storage, and disclose information.</li><br/>
                <br/>
                <span className="title">2. Information Collection</span><br/>
                <li>Personal Information: We securely collect only information required to maintain an account of name and email address.</li><br/>
                <li>Video Content: We securely store the video content you create and share in our cloud provider.</li><br/>
                <li>Usage Data: We collect anonymized data on how you use our platform, including access times, viewed pages, geographic region, and device information.</li><br/>
                <br/>
                <span className="title">3. Use of Information</span><br/>
                <li>To provide and maintain our platform.</li><br/>
                <li>To communicate with you about your account and our services.</li><br/>
                <li>To improve and customize the user experience.</li><br/>
                <li>For educational and research purposes, in accordance with applicable laws.</li><br/>
                <br/>
                <span className="title">4. Sharing of Information</span><br/>
                <li>With third-party service providers assisting our platform operation.</li><br/>
                <li>As required by law or to protect our rights and safety.</li><br/>
                <br/>
                <span className="title">5. Data Security</span><br/>
                <li>We implement security measures to protect your information from unauthorized access, alteration, and disclosure.</li><br/>
                <br/>
                <span className="title">6. Children's Privacy</span><br/>
                <li>Our platform is intended for use by individuals over the age of 13. We do not knowingly collect information from children under 13.</li><br/>
                <br/>
                <span className="title">7. Changes to This Policy</span><br/>
                <li>We may update this policy. We will notify you of any changes by posting the new policy on this page.</li><br/>
                <br/>
                <span className="title">8. Contact Us</span><br/>
                <li>For any questions or concerns regarding this privacy policy, please contact us at hello@brightvillage.org</li>
            </div>
        </div>
    );
}

export default Privacy;

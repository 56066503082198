import { useContext } from 'react';
import { Context } from '../../DataStore';
import { useHistory } from 'react-router-dom';

import * as strings from '../../data/strings';

import '../../styles/marketing/Features.scss';
const Features = () => {
	const { store } = useContext(Context);
	const history = useHistory();

	return (
		<div className="Features">
			<div className="contentWrapper">
				<div className="contentContent">
					<div className="contentBlock">
						<div className="contentTitle">
							{strings.default[store.language].Marketing.StartForFree}
						</div>
						<div className="contentDescription">
							{strings.default[store.language].Marketing.StartForFreeDescription}
						</div>
						<button 
							className="contentFeature"
							onClick={() => history.push('/register/')}>
							{strings.default[store.language].Marketing.StartForFreeCallout}
						</button>
					</div>
					<div className="contentBlock">
						<div className="contentTitle">
							{strings.default[store.language].Marketing.AffordablePricing}
						</div>
						<div className="contentDescription">
							{strings.default[store.language].Marketing.AffordablePricingDescription}
						</div>
						<button 
							className="contentFeature"
							onClick={() => history.push('/register/')}>
							{strings.default[store.language].Marketing.AffordablePricingCallout}
						</button>
					</div>
				</div>
				<div className="premiumWrapper">
					<div className="premium">
						<div className="premiumTitle">
							🪄 <span className="text">{strings.default[store.language].Marketing.MagicAIPrompts}</span>
						</div>
						<div className="premiumDescription">
							{strings.default[store.language].Package.UnlimitedMagicAIPromptsDescription}
						</div>
					</div>
					<div className="premium">
						<div className="premiumTitle">
							🔮 <span className="text">{strings.default[store.language].Marketing.UnlimitedRecording}</span>
						</div>
						<div className="premiumDescription">
							{strings.default[store.language].Package.UnlimitedRecordingLimitDescription}
						</div>
					</div>
					<div className="premium">
						<div className="premiumTitle">
							🎤 <span className="text">{strings.default[store.language].Package.AIVoiceToneAnalysis}</span>
						</div>
						<div className="premiumDescription">
							{strings.default[store.language].Marketing.AIVoiceToneAnalysis}
						</div>
					</div>
					<div className="premium">
						<div className="premiumTitle">
							✍️ <span className="text">{strings.default[store.language].Package.AIAutoTranscription}</span>
						</div>
						<div className="premiumDescription">
							{strings.default[store.language].Package.AIAutoTranscriptionDescription}
						</div>
					</div>
					<div className="premium">
						<div className="premiumTitle">
							🔎 <span className="text">{strings.default[store.language].Package.AIKeywordsAndInsights}</span>
						</div>
						<div className="premiumDescription">
							{strings.default[store.language].Package.AIKeywordsAndInsightsDescription}
						</div>
					</div>
					<div className="premium">
						<div className="premiumTitle">
							🔗 <span className="text">{strings.default[store.language].Package.DocumentAttachments}</span>
						</div>
						<div className="premiumDescription">
							{strings.default[store.language].Package.DocumentAttachmentsDescription}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Features;
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Footer from '../common/Footer';

import Marketing from '../marketing/Marketing';
import Login from './Login';
import Register from './Register';
import Dashboard from './Dashboard';
import Privacy from '../content/Privacy';
import TermsOfService from '../content/TermsOfService';

import * as utils from '../exports/utils';

import '../../styles/composite/Application.scss';
import '../../styles/exports/animation.scss';

const Application = () => {
  return (
    <Router>
      <div className={`Application${utils.isMobile() ? ' mobile' : ''}`}>
        <div className="mainContent">
          <Route 
            path={[
              "/",
              "/welcome/:school"
            ]}
            component={Marketing}
            exact />
          <Route 
            path={[
              "/login/"
            ]}
            component={Login}
            exact />
          <Route 
            path={[
              "/register/"
            ]}
            component={Register}
            exact />
          <Route 
            path={[
              "/dashboard/",
              "/dashboard/premium/",
              "/g/",
              "/g/:communityId",
              "/g/:communityId/:groupId",
              "/g/:communityId/:groupId/:spaceId",
              "/g/:communityId/:groupId/:spaceId/:assetId"
            ]}
            component={Dashboard} 
            exact />
          <Route 
            path={[
              "/privacy/"
            ]}
            component={Privacy}
            exact />
          <Route 
            path={[
              "/tos/"
            ]}
            component={TermsOfService}
            exact />
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default Application;

import ReactGA from 'react-ga4';
import { useContext, useState, useEffect } from 'react';
import { Context } from '../../DataStore';
import { useHistory } from "react-router-dom";

import { GoogleOAuthProvider } from '@react-oauth/google';
import Google from '../common/Google';

import Header from '../common/Header';
import Loading from '../common/Loading';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/composite/Register.scss';

const CommunityCode = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/community-code.svg`;
const CommunityNew = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/community-new.svg`;
const ValidateTrue = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/validate-true.svg`;
const ValidateFalse = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/validate-false.svg`;

const Register = () => {
	ReactGA.send({
		hitType: "pageview",
		page: "/register/",
		title: "Register"
	});

	const { store, dispatch } = useContext(Context);
	const history = useHistory();

	const [registerMode, setRegisterMode] = useState('code');
	const [registerStep, setRegisterStep] = useState(0);
	const [loading, setLoading] = useState(false);
	const [code, setCode] = useState(['', '', '', '', '', '']);

	const [community, setCommunity] = useState('');
	const [communityReturn, setCommunityReturn] = useState({ exists: true, title: '', cid: '' });

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordFocus, setPasswordFocus] = useState(false);
	const [role, setRole] = useState('Student');

	const [minimumNumber, setMinimumNumber] = useState(false);
	const [specialCharacter, setSpecialCharacter] = useState(false);
	const [minimumLowercase, setMinimumLowercase] = useState(false);
	const [minimumUppercase, setMinimumUppercase] = useState(false);
	const [minimumCharacters, setMinimumCharacters] = useState(false);

	const [socialData, setSocialData] = useState(null);
	const [useEmail, setUseEmail] = useState(false);

	const sendMessage = (data) => {
		const statusCopy = {
			...store.status,
			message: {
				type: data.type,
				text: data.text
			}
		}
		dispatch({
			type: 'status',
			data: statusCopy
		});
	};

	const registerText = [
		{
			main: strings.default[store.language].Register.Greeting,
			sub: strings.default[store.language].Register.GetRegistered
		},
		{
			main: strings.default[store.language].Register[registerMode === 'code' ? 'JoinCode' : 'NewCommunity'],
			sub: strings.default[store.language].Register[registerMode === 'code' ? 'SixCode' : 'CommunityName']
		},
		{
			main: strings.default[store.language].Register.CreateAccount,
			sub: (registerMode === 'code' ? `${strings.default[store.language].Register.ToJoin}${communityReturn.title.length ? `"${communityReturn.title}"` : strings.default[store.language].Register.ThisCommunity}` : `${strings.default[store.language].Register.ToStart}"${(community.length ? community : strings.default[store.language].Register.YourCommunity)}"${strings.default[store.language].Register.Now}`)
		}
	]

	const nextStep = (currentStep, choice) => {
		switch (currentStep) {
			case 0:
				setRegisterMode(choice);
				setCommunityReturn({ exists: choice === 'code', title: '', cid: '' });
				break;
			default:
				break;
		}
		setRegisterStep(currentStep + 1);
	}

	const renderStep = () => {
		switch (registerStep) {
			case 0:
				return (
					<div className="typeWrapper">
						<div className="type">
							<div className="choiceWrapper">
								<button
									className="choice"
									onClick={() => nextStep(0, 'code')}>
									<img
										className="choiceImage"
										src={CommunityCode}
										alt={strings.default[store.language].Register.HaveJoinCode} />
									<div className="choiceText">
										{strings.default[store.language].Register.HaveJoinCode}
									</div>
								</button>
							</div>
						</div>
						<div className="type">
							<div className="choiceWrapper">
								<button
									onClick={() => nextStep(0, 'community')}
									className="choice">
									<img
										className="choiceImage"
										src={CommunityNew}
										alt={strings.default[store.language].Register.CreateCommunity} />
									<div className="choiceText">
										{strings.default[store.language].Register.CreateCommunity}
									</div>
								</button>
							</div>
						</div>
					</div>
				)
			case 1:
				if (registerMode === 'code') {
					return (
						<>
							<div className="codeWrapper">
								{code.map((value, index) => (
									<div
										className={`characterWrapper ${index}${code.includes('') ? '' : ' ready'}`}
										key={`Code${index}`}>
										<input
											className={`joinCharacter joinInput${index}`}
											type="text"
											minLength={1}
											maxLength={1}
											onKeyUp={(event) => codeParse(index, event)}
											defaultValue={value} />
									</div>
								))}
							</div>
							<button
								className="submitCode"
								disabled={code.includes('')}
								onClick={() => submitCode()}>
								{strings.default[store.language].Modal.Submit}
							</button>
							{!communityReturn.exists &&
								<div className="communityError">
									Hmmmm. we could not find a community based on this Join Code.
								</div>
							}
						</>
					);
				} else {
					return (
						<div className="newWrapper">
							<input
								className="community"
								placeholder={strings.default[store.language].Menu.AddCommunityName}
								onChange={(event) => setCommunity(event.target.value)}
								value={community} />
							<button
								className="checkAvailability"
								disabled={!community.length}
								onClick={() => checkAvailability()}>
								{strings.default[store.language].LoginRegister.CheckAvailability}
							</button>
							{communityReturn.exists &&
								<div className="communityError">
									This community name has been taken. Please use a different name.
								</div>
							}
						</div>
					);
				}
			case 2:
				return (
					<>
						{useEmail ?
							<div className="registerForm">
								<div className="inputs">
									<div className="title">
										{strings.default[store.language].LoginRegister.FullName}
									</div>
									<input
										className="input name"
										type="name"
										onChange={(event) => setName(event.target.value)}
										placeholder={strings.default[store.language].LoginRegister.FullNamePlaceholder}
										value={name} />
								</div>
								<div className="inputs">
									<div className="title">
										{strings.default[store.language].LoginRegister.EmailAddress}
									</div>
									<input
										className="input email"
										type="email"
										onChange={(event) => setEmail(event.target.value)}
										placeholder="email@address.com"
										value={email} />
								</div>
								<div className="inputs">
									<div className="title">
										{strings.default[store.language].LoginRegister.Password}
									</div>
									<input
										className="input password"
										type="password"
										onChange={(event) => setPassword(event.target.value)}
										onKeyUp={(event) => validateInput(event)}
										onFocus={() => setPasswordFocus(true)}
										onBlur={() => setPasswordFocus(false)}
										placeholder={strings.default[store.language].LoginRegister.Password}
										value={password} />
								</div>
								<button
									className="submitRegister"
									disabled={!(constants.validate.email(email) && constants.validate.password(password) && constants.validate.name(name) && code.length)}
									onClick={() => submitRegister()}>
									{strings.default[store.language].Modal.Submit}
								</button>
								{passwordFocus &&
									<div className="passwordHint">
										<div className="triangle" />
										<div className={`validation${minimumUppercase ? ' validated' : ''}`}>
											<div className="status">
												<img
													className="validateImage"
													src={minimumUppercase ? ValidateTrue : ValidateFalse}
													alt={`Validate`} />
											</div>
											<div className="requirement">
												(1) {strings.default[store.language].LoginRegister.Uppercase}
											</div>
										</div>
										<div className={`validation${minimumLowercase ? ' validated' : ''}`}>
											<div className="status">
												<img
													className="validateImage"
													src={minimumLowercase ? ValidateTrue : ValidateFalse}
													alt={`Validate`} />
											</div>
											<div className="requirement">
												(1) {strings.default[store.language].LoginRegister.Lowercase}
											</div>
										</div>
										<div className={`validation${minimumNumber ? ' validated' : ''}`}>
											<div className="status">
												<img
													className="validateImage"
													src={minimumNumber ? ValidateTrue : ValidateFalse}
													alt={`Validate`} />
											</div>
											<div className="requirement">
												(1) {strings.default[store.language].LoginRegister.Number}
											</div>
										</div>
										<div className={`validation${specialCharacter ? ' validated' : ''}`}>
											<div className="status">
												<img
													className="validateImage"
													src={specialCharacter ? ValidateTrue : ValidateFalse}
													alt={`Validate`} />
											</div>
											<div className="requirement">
												(1) {strings.default[store.language].LoginRegister.SpecialCharacter} (!@#$%^&*)
											</div>
										</div>
										<div className={`validation${minimumCharacters ? ' validated' : ''}`}>
											<div className="status">
												<img
													className="validateImage"
													src={minimumCharacters ? ValidateTrue : ValidateFalse}
													alt={`Validate`} />
											</div>
											<div className="requirement">
												(8) {strings.default[store.language].LoginRegister.MinimumCharacters}
											</div>
										</div>
									</div>
								}
							</div> :
							<>
								<div className="socialRegister">
									<GoogleOAuthProvider clientId={constants.strings.googleClientID}>
										<Google
											source="Register"
											loginSuccess={(loginData) => submitSocial(loginData)} />
									</GoogleOAuthProvider>
								</div>

								<div className="or">
									or
								</div>

								<div className="emailRegister">
									<button
										className="useEmail"
										onClick={() => setUseEmail(!useEmail)}>
										Use Email Address
									</button>
								</div>
							</>
						}
					</>
				)
			default:
				return '';
		}
	}

	const codeParse = (index, event) => {
		if (index < 6) {
			let codeCopy = [...code];
			const valid = ((/^[a-z0-9]+$/i).test(event.target.value));

			switch (event.keyCode) {
				case 8:
					if (index > 0) {
						document.querySelectorAll(`.joinInput${index - 1}`)[0]?.focus();
						codeCopy[index] = event.target.value;
					}
					break;
				case 37:
					if (index > 0) {
						document.querySelectorAll(`.joinInput${index - 1}`)[0]?.focus();
					}
					break;
				case 39:
					document.querySelectorAll(`.joinInput${index + 1}`)[0]?.focus();
					break;
				case 9:
				case 16:
				case 38:
				case 40:
					break;
				default:
					if (valid) {
						codeCopy[index] = event.target.value;
						if (index < 5) {
							document.querySelectorAll(`.joinInput${index + 1}`)[0]?.focus();
						}
					}
					break;
			}
			setCode(codeCopy);
		}
	}

	const submitCode = async () => {
		setLoading(true);

		try {
			let data = {
				cid: code.join('').toUpperCase()
			}
			const url = `${constants.services.url.api}/community/verify/`;
			const response = await fetch(url, constants.services.config(data));
			const responseData = await response.json();
			setLoading(false);

			if (response.ok) {
				setCommunityReturn(responseData);
				if (responseData.exists) {
					setRegisterStep(registerStep + 1);
				}
			} else {
				sendMessage(constants.strings.messages('error', 'network'));
			}
		} catch (error) {
			setLoading(false);
			sendMessage(constants.strings.messages('error', 'network'));
		}
	}

	const checkAvailability = async () => {
		setLoading(true);

		try {
			let data = {
				title: community
			}
			const url = `${constants.services.url.api}/community/check/`;
			const response = await fetch(url, constants.services.config(data));
			const responseData = await response.json();
			setLoading(false);

			if (response.ok) {
				setCommunityReturn(responseData);
				setRole('Teacher');
				if (!responseData.exists) {
					setRegisterStep(registerStep + 1);
				}
			} else {
				sendMessage(constants.strings.messages('error', 'network'));
			}
		} catch (error) {
			setLoading(false);
			sendMessage(constants.strings.messages('error', 'network'));
		}
	}

	const validateInput = (event) => {
		const value = event.target.value;

		setMinimumNumber(constants.validate.minimumNumber(value));
		setSpecialCharacter(constants.validate.specialCharacter(value));
		setMinimumLowercase(constants.validate.minimumLowercase(value));
		setMinimumUppercase(constants.validate.minimumUppercase(value));
		setMinimumCharacters(constants.validate.minimumCharacters(value));
	}

	const submitRegister = async (registerData) => {
		const hasSocialData = registerData !== undefined && constants.utils.isObject(registerData);

		if ((hasSocialData ||
			(constants.validate.email(email) &&
				constants.validate.password(password) &&
				constants.validate.name(name)))
			&&
			(registerMode === 'code' ?
				((communityReturn.cid !== undefined && communityReturn.cid.length) &&
					(communityReturn.title !== undefined && communityReturn.title.length)) :
				community.length)) {
			try {
				let data = {
					name: hasSocialData ? registerData.name : name,
					email: hasSocialData ? registerData.email : email,
					password: hasSocialData ? registerData.password : password,
					role: role,
					cid: (registerMode === 'code' ? communityReturn.cid : null),
					community: (registerMode === 'code' ? communityReturn.title : community),
					image: hasSocialData ? registerData.image : ''
				}

				// UI effected
				setLoading(true);

				const url = `${constants.services.url.api}/user/create/`;
				const response = await fetch(url, constants.services.config(data));
				const responseData = await response.json();

				setLoading(false);

				if (response.ok) {
					if (responseData.status === 'Success') {
						const localSession = JSON.parse(localStorage.getItem('bvSocialData'));
						if (localSession !== null) {
							localStorage.removeItem('bvSocialData');
						};
						localStorage.setItem('bvSession', JSON.stringify(responseData.session));

						const storeCopy = {
							...store,
							profile: responseData.user,
							session: responseData.session,
							subscription: {
								active: false,
								type: null
							},
							communities: {
								data: responseData.communities,
								fetched: true
							}
						}
						dispatch({
							type: 'store',
							data: storeCopy
						});

						// Database refresh time
						history.push('/dashboard/');
					} else {
						sendMessage({ type: 'error', text: responseData.status });
					}
				} else {
					sendMessage(constants.strings.messages('error', 'network'));
				}
			} catch (error) {
				sendMessage(constants.strings.messages('error', 'network'));
			}
		} else {
			sendMessage(constants.strings.messages('error', 'network'));
		}
	}

	const backControl = () => {
		switch (registerStep) {
			case 0:
				if (localStorage.getItem('bvSocialData') !== undefined) {
					const localSession = JSON.parse(localStorage.getItem('bvSocialData'));
					if (localSession !== null) localStorage.removeItem('bvSocialData');
				}
				setTimeout(() => {
					history.push('/login/');
				}, 0);
				break;
			case 1:
				setRegisterStep(registerStep - 1);
				break;
			case 2:
				if (!useEmail) {
					setRegisterStep(registerStep - 1);
				}
				const localSession = JSON.parse(localStorage.getItem('bvSocialData'));
				if (localSession !== null) localStorage.removeItem('bvSocialData');
				setSocialData(null);
				setUseEmail(false);
				break;
			default:
				break;
		}
	}

	// Callback from create community or auto-logged in from login
	const submitSocial = async (loginData) => {
		if (loginData !== undefined && constants.utils.isObject(loginData)) {
			localStorage.setItem('bvSession', JSON.stringify(loginData.session));

			setLoading(true);

			let data = {
        cid: (registerMode === 'code' ? code.join('').toUpperCase() : null),
        uuid: loginData.session.uuid,
        title: (registerMode === 'code' ? communityReturn.title : community),
        sessionId: loginData.session.sessionId
      }

      const url = `${constants.services.url.api}/community/${registerMode === 'code' ? 'join' : 'create'}/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      if (response.ok) {
        if (responseData.status === 'Success') {
					setLoading(false);
					setTimeout(() => {
						history.push('/login/');
						
						setTimeout(() => {
							window.location.reload();
						},500);
					}, 0);
				} else {
					sendMessage(constants.strings.messages('error', 'network'));
				}
			}
		} else {
			if (localStorage.getItem('bvSocialData') !== undefined) {
				const localSession = JSON.parse(localStorage.getItem('bvSocialData'));
				if (localSession !== null) {
					submitRegister({
						name: localSession.name,
						email: localSession.email,
						password: constants.utils.generateRandomPassword(),
						image: localSession.image !== undefined ? localSession.image : ''
					});
				}
			}
		}
	}

	useEffect(() => {
		if (localStorage.getItem('bvSocialData') !== undefined) {
			const localSession = JSON.parse(localStorage.getItem('bvSocialData'));
			if (localSession !== null && socialData == null) {
				setSocialData(localSession);
			};
		}
	}, [socialData]);

	return (
		<div className="Register">
			<Header />
			<div className="wrapper">
				<div className="buttonWrapper">
					<button
						className="navigationButton"
						onClick={() => backControl()}>
						{strings.default[store.language].Content.Back}
					</button>
				</div>
				<Loading active={loading} />
				<div className="typeChoices">
					<div className="register">
						{socialData !== null &&
							<img
								className="socialImage"
								src={socialData.image}
								alt={socialData.name} />
						}
						<div className="main">
							{`${registerText[registerStep].main}${socialData !== null && registerStep === 0 ? ', ' : ''}${(socialData !== null && registerStep === 0) ? socialData.name.split(' ')[0] : ''}${registerStep === 0 ? '!' : ''}`}
						</div>
						<div className="sub">
							{registerText[registerStep].sub}
						</div>
					</div>
					<div className="stepRender">
						{renderStep()}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Register;

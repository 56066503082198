import { useContext } from 'react';
import { Context } from '../../DataStore';

import * as strings from '../../data/strings';

import '../../styles/marketing/WhatIs.scss';

const WhatIs = () => {
  const { store } = useContext(Context);

  return (
    <div className="WhatIs">
      <div className="whatIsWrapper">
        <div className="whatIs">
          <div className="whatIsText">
            <div className="whatIsImage">
              <img
                className="whatIsIcon"
                src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}png/section-2-thought.png`}
                alt="Thought equity" />
            </div>
            <div className="whatIsTitle">
              {strings.default[store.language].Marketing.ThoughtEquity}
            </div>
          </div>
          <div className="whatIsContent">
            {strings.default[store.language].Marketing.ThoughtEquityDescription}
          </div>
          <div className="whatIsGraphic">
            <img
              className="graphic"
              src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}png/section-1-record.png`}
              alt="Thought equity through our recorder" />
          </div>
        </div>
      </div>
      <div className="whatIsWrapper">
        <div className="whatIs">
          <div className="whatIsText">
            <div className="whatIsImage">
              <img
                className="whatIsIcon"
                src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}png/section-2-research.png`}
                alt="Research Backed" />
            </div>
            <div className="whatIsTitle">
              {strings.default[store.language].Marketing.ResearchBacked}
            </div>
          </div>
          <div className="whatIsContent">
            {strings.default[store.language].Marketing.ResearchBackedDescription}
          </div>
          <div className="whatIsGraphic">
            <img
              className="graphic"
              src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}png/section-2-research-graphic.png`}
              alt="Bright Village is research-backed" />
          </div>
        </div>
      </div>
      <div className="whatIsWrapper">
        <div className="whatIs">
          <div className="whatIsText">
            <div className="whatIsImage">
              <img
                className="whatIsIcon"
                src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}png/section-2-educators.png`}
                alt="Research Backed" />
            </div>
            <div className="whatIsTitle">
              {strings.default[store.language].Marketing.ByEducators}
            </div>
          </div>
          <div className="whatIsContent">
            {strings.default[store.language].Marketing.ByEducatorsDescription}
          </div>
          <div className="whatIsGraphic">
            <img
              className="graphic"
              src={`${process.env.REACT_APP_CF_MKT_ENDPOINT}png/section-1-share.png`}
              alt="Bright Village was made by educators" />
          </div>
        </div>
      </div>
    </div >
  );
}

export default WhatIs;

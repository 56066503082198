import { useContext } from 'react';
import { Context } from '../../DataStore';

import * as strings from '../../data/strings';

import '../../styles/marketing/ContentSection.scss';

const ContentSection = (props) => {
  const { store } = useContext(Context);

  return (
    <div className="ContentSection">
      <div className="contentWrapper">
        <div className="content">
          <div className="contentBar left">
            <div className="textWrapper">
              <div className="textHeadline">
                {strings.default[store.language].Marketing.AIAssistedDiscussions}
              </div>
              <div className="textSubtext">
                {strings.default[store.language].Marketing.AIAssistedDiscussionsDescription}
              </div>
              {/*<div className="contentLink">
                <a 
                  className="link" 
                  href="https://youtu.be/0B36-4BSgGg"
                  target="_blank" 
                  rel="noreferrer">
                    Watch 90-second overview &gt;
                  </a>
              </div>*/}
            </div>
          </div>
        </div>
        <div className="content">
          <div className="contentBar right">
            <div className="textWrapper">
              <div className="textHeadline">
                {strings.default[store.language].Marketing.KeywordsInsights}
              </div>
              <div className="textSubtext">
                {strings.default[store.language].Marketing.KeywordsInsightsDescription}
              </div>
              {/*<div className="contentLink">
                <a 
                  className="link" 
                  href="https://youtu.be/MpU0pEx1Z1Y"
                  target="_blank" 
                  rel="noreferrer">
                    Watch Gravity in action &gt;
                  </a>
              </div>*/}
            </div>
          </div>
        </div>
        <div className="content">
          <div className="contentBar left">
            <div className="textWrapper">
              <div className="textHeadline">
                {strings.default[store.language].Marketing.DifferentiatedLearning}
              </div>
              <div className="textSubtext">
                {strings.default[store.language].Marketing.DifferentiatedLearningDescription}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentSection;

import { useContext, useState } from 'react';
import { Context } from '../../DataStore';
import { useHistory } from 'react-router-dom';

import Reaction from '../common/Reaction';
import Vote from '../common/Vote';
import Video from '../common/Video';
import Keywords from '../common/Keywords';
import Participant from '../common/Participant';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/composite/ThreadView.scss';

const DefaultAvatar = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/default-robot.svg`;
const DeleteIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/delete.svg`;

const ThreadView = (props) => {
  const { store, dispatch } = useContext(Context);
  const history = useHistory();

  const [views, setViews] = useState([]);
  const [keywordLoading, setKeywordLoading] = useState(false);

  const sendMessage = (data) => {
    const statusCopy = {
      ...store.status,
      message: {
        type: data.type,
        text: data.text
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  };

  const closeThread = () => {
    props.setClosing(true);

    setTimeout(() => {
      props.setClosing(false)

      // Add to URL Route
      history.push(`/g/${store.status.community.cid}/${store.status.group.gid}/${store.status.space.sid}/`);

      // Clear from DataStore
      const statusCopy = {
        ...store.status,
        asset: {}
      }
      dispatch({
        type: 'status',
        data: statusCopy
      });
    }, 600);
  }

  const respondThread = () => {
    const magic = (store.status.space.magic !== undefined && store.status.space.magic);

    const statusCopy = {
      ...store.status,
      modal: {
        active: true,
        action: 'create',
        type: 'asset',
        store: 'assets',
        thread: store.status.asset.aid,
        data: {
          title: `${strings.default[store.language].AssetsView.SidebarWith} ${store.status.asset.creator.name.split(' ')[0]}`,
          inputTitle: strings.default[store.language].Modal.ShortDescription,
          placeholder: strings.default[store.language].AssetsView.ThreadDescriptionPlaceholder,
          privacySetting: false,
          magicAI: magic,
          creatorStudio: true,
          response: true
        }
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  const processView = async (e, aid, creator) => {
    if (!views.includes(aid)) {
      setViews([
        ...views,
        aid
      ]);
      let data = {
        uuid: store.profile.uuid,
        sessionId: store.session.sessionId,
        aid: aid,
        creator: creator
      }

      const url = `${constants.services.url.api}/view/create/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      if (response.ok && responseData.status !== 'Success') {
        const index = views.indexOf(aid);
        if (index > -1) {
          const newViews = views.splice(index, 1);
          setViews(newViews);
        }
      }
    }
  }

  const keywordInsight = async (keyword) => {
    setKeywordLoading(true);

    try {
      let data = {
        tokens: 50,
        subject: keyword,
        uuid: store.profile.uuid,
        sessionId: store.session.sessionId
      }

      const url = `${constants.services.url.api}/authenticity/ai/clue/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      setKeywordLoading(false);

      if (response.ok) {
        if (responseData.status === 'Success') {
          const statusCopy = {
            ...store.status,
            modal: {
              active: true,
              action: 'keyword',
              data: {
                title: keyword,
                description: responseData.content,
                youtube: responseData.youtube,
                news: responseData.news
              }
            }
          }
          dispatch({
            type: 'status',
            data: statusCopy
          });
        } else {
          sendMessage(constants.strings.messages('error', 'network'));
        }
      } else {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    } catch (error) {
      setKeywordLoading(false);
      sendMessage(constants.strings.messages('error', 'network'));
    }
  }

  return (
    <div className="ThreadView">
      <div
        className={`threadFade${props.closing ? ' closing' : ''}`}
        onClick={() => closeThread()} />
      <div className={`closeThreadWrapper${props.closing ? ' closing' : ''}`}>
        <button
          className="closeThread"
          onClick={() => closeThread()}>
          {`⇽ ${strings.default[store.language].ThreadView.Back}`}
        </button>
      </div>
      <div className={`threadModal${props.closing ? ' closing' : ''}`}>
        <div className={`media ${store.status.asset.aid}`}>
          <Video
            className="videoPlayer"
            setSrc={store.status.asset.data.location}
            setPoster={`${store.status.asset.aid}.gif`}
            onPlay={(e) => processView(e, store.status.asset.aid, store.status.asset.creator.uuid)}
            transcript={store.status.asset.data.transcript !== undefined ? store.status.asset.data.transcript : ''}
            type={store.status.asset.data.type}
            level="mainThread" />
        </div>

        <div className="dataWrapper">
          <div className="emojiWrapper">
            {(store.status.space.type === 'conversation' || store.status.space.spaceType === 'conversation') ?
              <Reaction
                aid={store.status.asset.aid}
                data={store.status.asset.reactions}
                dark /> :
              <Vote
                aid={store.status.asset.aid}
                data={store.status.asset.votes} />
            }
          </div>
          <div className="responderWrapper">
            <div className="responder">
              {store.status.asset.creator.image === undefined || store.status.asset.creator.image === '' ?
                <Participant
                  i={0}
                  participant={{
                    name: store.status.asset.creator.name
                  }}
                  display /> :
                <div
                  className="responderAvatar"
                  style={{ backgroundImage: `url(${store.status.asset.creator.image !== undefined && store.status.asset.creator.image !== '' ? constants.services.s3ToCloudfrontMedia(store.status.asset.creator.image) : DefaultAvatar})` }} />
              }
            </div>
            <div className="metadata">
              <div className="name">
                {store.status.asset.creator.name}
              </div>
              <div className="handle">
                {store.status.asset.creator.role}
              </div>
              <div className="creation">
                {constants.utils.parseDateTime(store.status.asset.creation)}
              </div>
            </div>
          </div>
          <div className="descriptionWrapper">
            <div className="triangleUp" />
            <div className="description">
              {store.status.asset.data.description}
            </div>
            {store.status.asset.data.keywords !== undefined && store.status.asset.data.keywords.length ?
              <Keywords
                data={store.status.asset.data.keywords}
                loading={keywordLoading}
                keywordInsight={(keyword) => keywordInsight(keyword)}
                aid={store.status.asset.aid}
                dark
                update={{
                  allowed: store.status.asset.creator.uuid === store.profile.uuid,
                  assetType: 'threadMain'
                }} /> : ''
            }
          </div>
        </div>

        <div className="respondWrapper">
          <button
            className={`respond${(store.status.space.magic !== undefined && store.status.space.magic) ? ' magic' : ''}`}
            onClick={() => respondThread()}>
            {`${(store.status.space.magic !== undefined && store.status.space.magic) ? '🪄 ' : ''}${strings.default[store.language].AssetsView.Respond}${store.status.asset.creator.name.length ? `${strings.default[store.language].AssetsView.To} ${store.status.asset.creator.name.split(' ')[0]}` : ''}`}
          </button>
        </div>

        <div className="responses">
          {store.status.asset.thread.length ?
            <div className="responsesWrapper">
              {store.status.asset.thread.map((response, i) => (
                <div
                  className="responseWrapper"
                  key={`ResponsesWrapper${i}`}>
                  <div className="response">
                    <Video
                      className="videoPlayer"
                      setSrc={response.data.location}
                      setPoster={`${response.aid}.gif`}
                      onPlay={(e) => processView(e, response.aid, response.creator.uuid)}
                      transcript={response.data.transcript !== undefined ? response.data.transcript : ''}
                      type={response.data.type}
                      level="thread" />
                    {(response.creator.uuid === store.profile.uuid) &&
                      <button className="deleteResponse">
                        <img
                          className="deleteIcon"
                          src={DeleteIcon}
                          onClick={(event) => props.deleteThread(response, event)}
                          alt="Delete media" />
                      </button>
                    }
                  </div>
                  <div className="data">
                    <div className="emojiWrapper">
                      {(store.status.space.type === 'conversation' || store.status.space.spaceType === 'conversation') ?
                        <Reaction
                          aid={response.aid}
                          data={response.reactions}
                          dark /> :
                        <Vote
                          aid={response.aid}
                          data={response.votes} />
                      }
                    </div>
                    <div className="responderWrapper">
                      <div className="responder">
                        {response.creator.image === undefined || response.creator.image === '' ?
                          <Participant
                            i={0}
                            participant={{
                              name: response.creator.name
                            }}
                            display /> :
                          <div
                            className="responderAvatar"
                            style={{ backgroundImage: `url(${response.creator.image !== undefined && response.creator.image !== '' ? constants.services.s3ToCloudfrontMedia(response.creator.image) : DefaultAvatar})` }} />
                        }
                      </div>
                      <div className="metadata">
                        <div className="name">
                          {response.creator.name}
                        </div>
                        <div className="handle">
                          {response.creator.role}
                        </div>
                        <div className="creation">
                          {constants.utils.parseDateTime(response.creation)}
                        </div>
                      </div>
                    </div>
                    <div className="descriptionWrapper">
                      <div className="triangleUp" />
                      <div className="description">
                        {response.data.description}
                      </div>
                      {response.data.keywords !== undefined && response.data.keywords.length ?
                        <Keywords
                          data={response.data.keywords}
                          loading={keywordLoading}
                          keywordInsight={(keyword) => keywordInsight(keyword)}
                          aid={response.aid}
                          dark
                          update={{
                            allowed: response.creator.uuid === store.profile.uuid,
                            assetType: 'thread',
                            responseIndex: i
                          }} /> : ''
                      }
                    </div>
                  </div>
                </div>
              ))}
            </div> :
            <div className="responseEmpty">
              {strings.default[store.language].AssetsView.NoResponses}
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default ThreadView;

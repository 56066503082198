import Header from '../common/Header';

import '../../styles/content/TermsOfService.scss';

const TermsOfService = () => {
    return (
        <div className="TermsOfService">
            <Header />
            <div className="header">
                <span className="title">Terms of Service</span>
            </div>
            <div className="content">
                <span className="title">Effective Date: November 19, 2023</span><br/>
                <br/>
                <span className="title">1. Acceptance of Terms</span><br/>
                <li>By accessing or using Bright Village, you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, do not use our services.</li><br/>
                <br/>
                <span className="title">2. Description of Service</span><br/>
                <li>Bright Village provides an asynchronous AI-powered video communication platform primarily intended for educational purposes.</li><br/>
                <br/>
                <span className="title">3. User Obligations</span><br/>
                <li>You agree to use [Platform Name] for lawful, non-commercial, educational purposes only.</li><br/>
                <li>You are responsible for all content you transmit through our platform.</li><br/>
                <li>You must respect the privacy and intellectual property rights of others.</li><br/>
                <br/>
                <span className="title">4. User Accounts</span><br/>
                <li>You must provide accurate and complete registration information.</li><br/>
                <li>You are responsible for safeguarding your account and should notify us immediately of any unauthorized use.</li><br/>
                <br/>
                <span className="title">5. Content Ownership and License</span><br/>
                <li>You retain all rights to the content you upload but grant Bright Village a license to use, modify, display, and distribute your content in connection with the service.</li><br/>
                <li>Bright Village reserves the right to remove any content that violates these Terms or applicable laws.</li><br/>
                <br/>
                <span className="title">6. Prohibited Conduct</span><br/>
                <li>You may not use the service to engage in illegal, harmful, or offensive activities.</li><br/>
                <li>You may not attempt to disrupt the service or exploit any vulnerabilities in the system.</li><br/>
                <br/>
                <span className="title">7. Termination</span><br/>
                <li>Bright Village may terminate or suspend your access to the service for any violation of these Terms.</li><br/>
                <br/>
                <span className="title">8. Disclaimers and Limitation of Liability</span><br/>
                <li>The service is provided “as is” without warranties of any kind.</li><br/>
                <li>Bright Village is not liable for any indirect, incidental, or consequential damages resulting from the use of the service.</li><br/>
                <br/>
                <span className="title">9. Modification of Terms</span><br/>
                <li>We reserve the right to modify these Terms at any time. Your continued use of the service following any changes indicates your acceptance of the new Terms.</li><br/>
                <br/>
                <span className="title">10. Governing Law</span><br/>
                <li>These Terms shall be governed by the laws of Delaware and United States.</li><br/>
                <br/>
                <span className="title">11. Governing Law</span><br/>
                <li>For any questions or concerns regarding these Terms, please contact us at hello@brightvillage.org</li>
            </div>
        </div>
    );
}

export default TermsOfService;

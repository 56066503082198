import { forwardRef, useContext, useState, useRef, useImperativeHandle } from 'react';
import { Context } from '../../DataStore';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import Loading from '../common/Loading';

import ActionBar from './ActionBar';

import '../../styles/recorder/Recorder.scss';

const videoIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/videoIcon.svg`;
const screenIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/screenIcon.svg`;
const voiceIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/voiceIcon.svg`;
const InfoIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/info.svg`;

const Recorder = forwardRef((props, ref) => {
  const { store, dispatch } = useContext(Context);
  const {
    transcript,
    //listening,
    resetTranscript/*,
    browserSupportsSpeechRecognition*/
  } = useSpeechRecognition();

  const [loadingCamera, setLoadingCamera] = useState(false);
  const [cameraStream, setCameraStream] = useState(window.MediaRecorder === undefined ? "" : new window.MediaRecorder(new MediaStream()));
  const [recordedVideoTime, setRecordedVideoTime] = useState('00:00');
  const [echoCancelled, setEchoCancelled] = useState(false);
  const [mediaRecorded, setMediaRecorded] = useState(false);
  const [pausedRecording, setPausedRecording] = useState(false);

  // New states
  const [queueing, setQueueing] = useState(false);
  const [countdown, setCountdown] = useState(3);

  // Playback states
  const [currentVideoTime, setCurrentVideoTime] = useState('00:00');

  const [loading, setLoading] = useState(false);

  // Check if WebRTC is enabled
  const webRTCEnabled = (window.MediaRecorder !== undefined && window.MediaSource !== undefined);

  // Originality Score
  const [score, setScore] = useState('');
  const [tone, setTone] = useState('');
  const [scoreGrade, setScoreGrade] = useState('green');
  const [infoHover, setInfoHover] = useState(false);
  const [toneHover, setToneHover] = useState(false);

  // Document element references
  const refs = {
    scrollPosition: useRef(0),
    videoId: useRef(''),
    previewVideo: useRef(null),
    recordedVideo: useRef(null),
    echoCancel: useRef(null),
    recordStop: useRef(null),
    playPause: useRef(null),
    totalVideoTime: useRef('0:00'),
    audioMonitor: useRef(null),
    commentBox: useRef(null),
    mediaTitle: useRef(null),
    audioContext: useRef(null),
    seconds: useRef(0),
    timer: useRef(null),
    maxTimer: useRef(null)
  }

  // Toggle DataStore activity
  const storeToggleRecording = (recording) => {
    const statusCopy = {
      ...store.status,
      recorder: {
        ...store.status.recorder,
        recording: recording,
      }
    }

    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  // Step 3: Initialize and success callback for stream
  const handleSuccess = (stream) => {
    window.stream = stream;
    refs.previewVideo.current.srcObject = stream;
    refs.previewVideo.current.play();

    let maxLevel = 0;
    let oldLevel = 0;
    let instantLevel = 0.0;

    if (refs.audioContext.current !== null) {
      refs.audioContext.current.close();
      refs.audioContext.current = null;
    }
    refs.audioContext.current = new AudioContext()

    const canvasContext = refs.audioMonitor.current.getContext('2d');
    const microphone = refs.audioContext.current.createMediaStreamSource(stream);
    const processor = refs.audioContext.current.createScriptProcessor(1024, 1, 1);

    microphone.connect(processor);
    processor.connect(refs.audioContext.current.destination);
    processor.onaudioprocess = (event) => {
      let inputLevel = event.inputBuffer.getChannelData(0);
      let sumLevel = 0.0;

      for (var i = 0; i < inputLevel.length; ++i) {
        sumLevel += inputLevel[i] * inputLevel[i];
      }

      instantLevel = Math.sqrt(sumLevel / inputLevel.length);
      maxLevel = Math.max(maxLevel, instantLevel);
      instantLevel = Math.max(instantLevel, oldLevel - 0.008);
      oldLevel = instantLevel;

      if (refs.audioMonitor.current !== null) {
        canvasContext.clearRect(0, 0, refs.audioMonitor.current.width, refs.audioMonitor.current.height);
        canvasContext.fillStyle = 'rgba(20,230,100,0.8)';
        canvasContext.fillRect(0, 0, (refs.audioMonitor.current.width) * (instantLevel / maxLevel), (refs.audioMonitor.current.height));
      }
    }

    setCameraStream(new MediaRecorder(window.stream, {
      mimeType: constants.utils.isSafari() ? 'video/mp4; codecs=avc1' : 'video/webm; codecs=avc1'
    }));
    setRecordedVideoTime(store.status.modal.data.magicAI ? '00:00' : '01:00');
    props.setCameraStarted(true);

    const statusCopy = {
      ...store.status,
      recorder: {
        ...store.status.recorder,
        started: true,
      }
    }

    dispatch({
      type: 'status',
      data: statusCopy
    });

    setLoadingCamera(false);
    setLoading(false);

    return new Promise(resolve => refs.previewVideo.current.onplaying = resolve);
  }

  // Step 2: Initialize the camera
  const init = async (constraints) => {
    try {
      if (props.recordType.current === 'screen') {

        const cameraError = () => {
          stopCamera(false);
          setLoadingCamera(false);
          setLoading(false);
        }

        navigator.mediaDevices.getDisplayMedia(constraints).then((screenStream) => {
          //Check microphones
          let microphones = 0;
          navigator.mediaDevices.enumerateDevices().then((devices) => {
            devices.forEach((device) => {
              if (device.kind === "audioinput") {
                microphones++;
              }
            });

            if (microphones === 0) {
              handleSuccess(screenStream);
            } else {
              navigator.mediaDevices.getUserMedia({ audio: true }).then((microphoneStream) => {
                let composedStream = new MediaStream();

                // Add screen track
                screenStream.getVideoTracks().forEach((videoTrack) => {
                  composedStream.addTrack(videoTrack);
                });

                // System audio was shared
                if (screenStream.getAudioTracks().length) {
                  // Merge system audio with the microphone audio
                  let context = new AudioContext();
                  let audioDestination = context.createMediaStreamDestination();

                  const systemSource = context.createMediaStreamSource(screenStream);
                  const systemGain = context.createGain();
                  systemGain.gain.value = 1.0;
                  systemSource.connect(systemGain).connect(audioDestination);

                  if (microphoneStream && microphoneStream.getAudioTracks().length) {
                    const microphoneSource = context.createMediaStreamSource(microphoneStream);
                    const microphoneGain = context.createGain();
                    microphoneGain.gain.value = 1.0;
                    microphoneSource.connect(microphoneGain).connect(audioDestination);
                  }

                  audioDestination.stream.getAudioTracks().forEach((audioTrack) => {
                    composedStream.addTrack(audioTrack);
                  });
                } else {
                  // Add only microphone audio
                  microphoneStream.getAudioTracks().forEach((microphoneTrack) => {
                    composedStream.addTrack(microphoneTrack);
                  });
                }

                handleSuccess(composedStream);
              }).catch((_) => {
                cameraError();
              });
            }
          }).catch((_) => {
            cameraError();
          });
        }).catch((_) => {
          cameraError();
        });
      } else {
        const userMedia = navigator.mediaDevices.getUserMedia(constraints);
        userMedia.then((stream) => {
          handleSuccess(stream);
        });
      }
    } catch (e) {
      stopCamera(false);
      setLoadingCamera(false);
      setLoading(false);
    }
  }

  // Step 1: Start the recorder
  const startMultiRecorder = async (type) => {
    setLoadingCamera(true);
    setLoading(true);

    props.setRecordType(type);

    let params = {}
    switch (type) {
      case 'video':
        params = {
          width: 800,
          height: 450
        }
        break;
      case 'voice':
        params = false;
        break;
      case 'screen':
        params = {
          cursor: 'always'
        }
        break;
      default:
        params = {}
    }

    const constraints = {
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        sampleRate: 44100
      },
      video: params
    };

    if (webRTCEnabled) {
      props.setRecordedBlobs([]);
      setLoading(true);

      await init(constraints);
    } else {
      setLoadingCamera(false);
      const statusCopy = {
        ...store.status,
        message: {
          type: 'error',
          text: constants.strings.messages('error', 'camera')
        }
      }
      dispatch({
        type: 'status',
        data: statusCopy
      });
    }
  }

  const pauseRecording = () => {
    clearInterval(refs.timer.current);
    setPausedRecording(true);
    cameraStream.pause();
  }

  const resumeRecording = () => {
    const pad = (value) => value > 9 ? value : `0${value}`;
    refs.timer.current = setInterval(() => {
      const videoTimeString = `${(store.status.modal.data.magicAI || (!store.status.modal.data.magicAI && refs.seconds.current < 60)) ? pad(parseInt(refs.seconds.current / 60, 10)) : '00'}:${pad((store.status.modal.data.magicAI ? ++refs.seconds.current : --refs.seconds.current) % 60)}`;
      setRecordedVideoTime(videoTimeString);
      refs.totalVideoTime.current = videoTimeString;
    }, 1000);
    if (!store.status.modal.data.magicAI) {
      refs.maxTimer.current = setTimeout(() => {
        clearInterval(refs.timer.current);
        stopRecording();
      }, 60000 * 1.0);
    }
    setPausedRecording(false);
    cameraStream.resume();
  }

  const stopCamera = (redo, setStore) => {
    SpeechRecognition.stopListening();
    setScore('');
    setTone('');
    props.setFinalTranscript('');
    resetTranscript();

    if (window.stream !== undefined) {
      window.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }

    const stoppedVideos = [refs.previewVideo.current, refs.recordedVideo.current];
    for (const video of stoppedVideos) {
      if (video !== null) {
        video.pause();
        video.srcObject = null;
        video.src = null;
        video.removeAttribute('src');
      }
    }
    setMediaRecorded(false);
    props.setCameraStarted(false);
    props.setRecordedBlobs([]);

    refs.seconds.current = 0;
    refs.timer.current = null;
    refs.maxTimer.current = null;

    setPausedRecording(false);

    if (setStore !== undefined && setStore) {
      const statusCopy = {
        ...store.status,
        recorder: {
          ...store.status.recorder,
          started: false,
        }
      }

      dispatch({
        type: 'status',
        data: statusCopy
      });
    }

    if (redo !== undefined && redo) {
      startMultiRecorder(props.recordType.current);
    } else {
      props.setRecordType('');
    }
  }

  const echoCancel = () => {
    if (window.stream !== null) {
      const cancellationChecked = refs.echoCancel.current.checked;
      window.stream.getTracks()[1].echoCancellation = cancellationChecked;
      setEchoCancelled(cancellationChecked);
    }
  }

  const countdownRecorder = () => {
    setQueueing(true);

    setTimeout(() => {
      setCountdown(2);
      setTimeout(() => {
        setCountdown(1);
        setTimeout(() => {
          setScore('');
          setTone('');
          props.setFinalTranscript('');
          resetTranscript();
          setQueueing(false);
          setCountdown(3);
          startRecording();
        }, 1000);
      }, 1000);
    }, 1000);
  }

  const startRecording = () => {
    let recordedBlobBuffer = [];

    const handleDataAvailable = (event) => {
      if (event.data && event.data.size > 0) {
        recordedBlobBuffer.push(event.data);
      }
    }

    setCameraStream(new MediaRecorder(window.stream, {
      mimeType: constants.utils.isSafari() ? 'video/mp4; codecs=avc1' : 'video/webm; codecs=avc1'
    }));
    cameraStream.ondataavailable = handleDataAvailable;

    // Set video timers with maximums
    refs.seconds.current = store.status.modal.data.magicAI ? 0 : 60;
    const pad = (value) => value > 9 ? value : `0${value}`;
    refs.timer.current = setInterval(() => {
      const videoTimeString = `${(store.status.modal.data.magicAI || (!store.status.modal.data.magicAI && refs.seconds.current < 60)) ? pad(parseInt(refs.seconds.current / 60, 10)) : '00'}:${pad((store.status.modal.data.magicAI ? ++refs.seconds.current : --refs.seconds.current) % 60)}`;
      setRecordedVideoTime(videoTimeString);
      refs.totalVideoTime.current = videoTimeString;
    }, 1000);
    
    if (!store.status.modal.data.magicAI) {
      refs.maxTimer.current = setTimeout(() => {
        clearInterval(refs.timer.current);
        stopRecording();
      }, 60000 * 1.0);
    }

    // Recorder Stopped
    cameraStream.onstop = () => {
      clearInterval(refs.timer.current);
      clearInterval(refs.maxTimer.current);
      props.setRecordedBlobs(recordedBlobBuffer);

      // Combine all the video data from the buffer and set as source (to watch before submitting)
      const superBuffer = new Blob(recordedBlobBuffer, {
        type: constants.utils.isSafari() ? 'video/mp4; codecs=avc1' : 'video/webm; codecs=avc1'
      });
      refs.recordedVideo.current.srcObject = null;
      refs.recordedVideo.current.src = null;
      refs.recordedVideo.current.src = window.URL.createObjectURL(superBuffer);

      // Change UI displayed video time on video events
      refs.recordedVideo.current.ontimeupdate = () => {
        if (refs.recordedVideo.current !== null) {
          setCurrentVideoTime(new Date(refs.recordedVideo.current.currentTime * 1000).toISOString().substr(14, 5));
        }
      };
      refs.recordedVideo.current.onended = () => {
        refs.recordedVideo.current.currentTime = 0;
      };
    };

    cameraStream.onstart = () => {
      setCameraStream(cameraStream);
    }
    // Set the buffer chunk size on start of stream recording
    cameraStream.start(10);

    SpeechRecognition.startListening({ continuous: true });

    storeToggleRecording(true);
    setMediaRecorded(false);
  }

  const stopRecording = async () => {
    SpeechRecognition.stopListening();

    cameraStream.stop();
    window.stream.getTracks().forEach((track) => {
      track.stop();
    });
    storeToggleRecording(false);
    setMediaRecorded(true);

    if (store.status.modal.data.magicAI) {
      if (transcript.length) {
        try {
          let data = {
            uuid: store.profile.uuid,
            sessionId: store.session.sessionId,
            transcript: constants.utils.cleanText(transcript)
          }
          const url = `${constants.services.url.api}/authenticity/score/`;
          const response = await fetch(url, constants.services.config(data));
          const responseData = await response.json();

          if (response.ok) {
            if (responseData.status === 'Success') {
              const originalityData = responseData.data.originality.documents[0];

              const averageScore = originalityData.average_generated_prob;
              const returnedScore = originalityData.completely_generated_prob;
              let score = (
                (returnedScore < 0.12 && averageScore === 0) ? 100 :
                  (returnedScore < 0.82 && averageScore === 1) ? 0 :
                    (100 - (returnedScore * 100)));

              const toneData = responseData.data.sentiment;
              const transcriptData = responseData.data.transcript;

              switch (true) {
                case score === 0:
                  setScoreGrade('clear');
                  break;
                case score <= 40:
                  setScoreGrade('red');
                  break;
                case score <= 60:
                  setScoreGrade('orange');
                  break;
                case score <= 80:
                  setScoreGrade('yellow');
                  break;
                default:
                  setScoreGrade('green');
              }

              setScore(Math.floor(score));
              setTone(toneData);
              props.setFinalTranscript(transcriptData);
            } else {
              props.sendMessage(constants.strings.messages('error', 'network'));
            }
          } else {
            props.sendMessage(constants.strings.messages('error', 'network'));
          }
        } catch (error) {
          props.sendMessage(constants.strings.messages('error', 'network'));
        }
      } else {
        setScore(100);
        setScoreGrade('green');
        setTone({
          positive: 0.5,
          negative: 0.5
        });
        props.setFinalTranscript(transcript);
      }
    }

    const statusCopy = {
      ...store.status,
      recorder: {
        ...store.status.recorder,
        started: false,
        recording: false
      }
    }

    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  const playPauseMedia = (play) => {
    (play ? refs.recordedVideo.current.play() : refs.recordedVideo.current.pause());
  };

  const redoRecording = () => {
    setScore('');
    setTone('');
    props.setFinalTranscript('');
    resetTranscript();

    if (refs.recordedVideo.current !== null) {
      if (!refs.recordedVideo.current.paused) {
        playPauseMedia(false);
      }
      refs.recordedVideo.current.pause();
      refs.recordedVideo.current.srcObject = null;
      refs.recordedVideo.current.src = null;
      stopCamera(true);
    }
  };

  // Controls Object
  const controls = [
    {
      className: 'playerButton',
      disabled: !mediaRecorded,
      onClick: () => redoRecording(),
      text: 'Redo'
    },
    {
      className: 'playerButton',
      disabled: !props.cameraStarted || queueing || loadingCamera || store.status.recorder.recording,
      onClick: () => stopCamera(false, true),
      text: 'Camera Off'
    }
  ]

  // Button image generators
  const generateIcon = (type) => {
    switch (type) {
      case 'video':
        return videoIcon;
      case 'voice':
        return voiceIcon;
      case 'screen':
        return screenIcon;
      case 'mirror':
        return videoIcon;
      default:
        return ''
    }
  }

  const buildButton = (action) => {
    switch (action) {
      case 'Record':
        return (
          <div className="iconWrapper">
            <div className="icon record"></div>
          </div>
        )
      case 'Stop':
        return (
          <div className="iconWrapper">
            <div className="icon stop"></div>
          </div>
        )
      case 'Play':
        return (
          <div className="iconWrapper">
            <div className="icon play"></div>
          </div>
        )
      case 'Pause':
        return (
          <div className="iconWrapper">
            <div className={`icon pauseWrapper${pausedRecording ? ' paused' : ''}`}>
              <div className='lineWrapper'>
                <div className="pause"></div>
              </div>
              <div className='lineWrapper'>
                <div className="pause"></div>
              </div>
            </div>
          </div>
        )
      case 'Redo':
        return (
          <div className="iconWrapper">
            <div className="icon redoWrapper">
              <div className="redoCircle"></div>
              <div className="redoBlock"></div>
              <div className="redoTriangle"></div>
            </div>
          </div>
        )
      case 'Camera Off':
        return (
          <div className="iconWrapper">
            <div className="icon cameraOffWrapper">
              <div className="offDevice">
                <div className="delete">
                  <div className="plus vertical"></div>
                  <div className="plus horizontal"></div>
                </div>
              </div>
            </div>
          </div>
        )
      case 'Submit':
        return (
          <div className="iconWrapper">
            <div className="icon submitWrapper">
              <div className="confirmWrapper">
                <div className="checkmarkLong"></div>
                <div className="checkmarkShort"></div>
              </div>
            </div>
          </div>
        )
      case 'PauseResume':
        return (
          <div className="iconWrapper">
            <div className="icon">
              {`${pausedRecording ? strings.default[store.language].Recorder.Resume : strings.default[store.language].Recorder.Pause} ${strings.default[store.language].Recorder.Recording}`}
            </div>
          </div>
        )
      default:
        return action;
    }
  }

  const buttons = !props.mirrorMode ? [
    {
      className: 'recorderCameraStart',
      type: 'video'
    },
    {
      className: 'recorderVoiceStart',
      type: 'voice'
    },
    {
      className: 'recorderScreenStart',
      type: 'screen'
    }
  ] : [
    {
      className: 'recorderCameraStart',
      type: 'video'
    }
  ]

  const determineSentiment = (tone) => {
    switch (true) {
      case ((tone.positive >= 0.4 && tone.positive < 0.6)):
        return strings.default[store.language].Recorder.Neutral;
      case (tone.positive >= 0.6):
        return strings.default[store.language].Recorder.Positive;
      case (tone.positive < 0.4):
        return strings.default[store.language].Recorder.Negative;
      default:
        return strings.default[store.language].Recorder.Neutral;
    }
  }

  useImperativeHandle(ref, () => ({
    closeRecorder() {
      stopCamera();
    }
  }));

  return (
    <div className={`Recorder${props.className ?? ''}${props.mirrorMode ? ' mirrorMode' : ''}`}>

      <Loading active={loading} />

      {/* Video Recorder */}
      <div className={`recorderCamera${props.recordType.current === 'video' ? ' mirrored' : ''}`}>
        <video
          className={`previewVideo ${props.recordType.current}`}
          ref={refs.previewVideo}
          playsInline
          muted />
        {!props.cameraStarted ?
          <div className={`recorderWrapper${loadingCamera ? ' disabled' : ''}`}>
            {buttons.map((button, i) => (
              <div
                className="recorderButtonWrapper"
                key={i}>
                <button
                  className={`recorderButton ${button.className}`}
                  onClick={() => startMultiRecorder(button.type)}>
                  <div className="contentWrapper">
                    <div className="mediaType">
                      <img
                        className="mediaIndicator"
                        src={generateIcon(button.type)}
                        alt={`${constants.utils.capitalizeFirst(button.type)} Icon`} />
                    </div>
                    <div className="buttonTitle">
                      {`${strings.default[store.language].Recorder[constants.utils.capitalizeFirst(button.type)]}`}
                    </div>
                  </div>
                </button>
              </div>
            ))}
          </div> :
          ''
        }
      </div>

      {(props.cameraStarted && !mediaRecorded && !props.mirrorMode) &&
        <div className="recordControls">
          <div className="recControlWrapper">
            <button
              className="recControl pause"
              disabled={!store.status.recorder.recording}
              onClick={() => pausedRecording ? resumeRecording() : pauseRecording()}>
              {buildButton('Pause')}
              <div className="textWrapper">
                {buildButton('PauseResume')}
              </div>
            </button>
          </div>
        </div>
      }

      <div className={`timeWrapper${!props.cameraStarted || loadingCamera || mediaRecorded ? '' : props.mirrorMode ? '' : ' active'}`}>
        <div className="recorderStatus">
          <div className={`statusShape${store.status.recorder.recording ? ' recording' : ' idle'}`} />
        </div>
        <div className={`recorderTime${mediaRecorded ? '' : ' active'}`}>
          {recordedVideoTime}
        </div>
        <div className={`playerTime${mediaRecorded ? ' active' : ''}`}>
          <div className="playTime">
            {currentVideoTime}
          </div>
          <div className="playDivider">
            |
          </div>
          <div className="totalTime">
            {refs.totalVideoTime.current}
          </div>
        </div>
      </div>

      {/* Microphone Monitor */}

      <div className={`monitorWrapper${props.cameraStarted && !mediaRecorded && !props.mirrorMode ? ' active' : ''}`}>
        <div className="voiceMicrophone">
          <div className="circleWrapper">
            <div className="circle small"></div>
          </div>
          <div className="circleWrapper">
            <div className="circle medium"></div>
          </div>
          <div className="circleWrapper">
            <div className="circle large"></div>
          </div>
        </div>
        <div className="volumeWrapper">
          <canvas
            className="volumeMonitor"
            ref={refs.audioMonitor}></canvas>
        </div>
      </div>

      {!props.mirrorMode &&
        <div className="buttonControls">
          <div className="buttonWrapper">
            <button
              className="playerButton"
              ref={refs.recordStop}
              disabled={!props.cameraStarted || queueing || loadingCamera || mediaRecorded}
              onClick={() => store.status.recorder.recording ? stopRecording() : countdownRecorder()}>
              {buildButton(store.status.recorder.recording ? 'Stop' : 'Record')}
            </button>
          </div>

          {controls.map((control, i) => (
            <div
              className="buttonWrapper"
              key={i}>
              <button
                className={control.className}
                disabled={control.disabled}
                onClick={control.onClick}>
                {buildButton(control.text)}
              </button>
            </div>
          ))}

        </div>
      }

      {queueing &&
        <div className="queueingWrapper">
          <div className="countdownWrapper">
            <div className="countdownNumber">
              {countdown}
            </div>
          </div>
        </div>
      }

      {(!props.mirrorMode && store.status.modal.data.magicAI) &&
        <>
          <div className="originalityWrapper">
            <div className="originalityInfo">
              {infoHover &&
                <div className="infoBubble">
                  {strings.default[store.language].Recorder.InfoHover}
                  <div className="triangle" />
                </div>
              }
              <div className="info">
                <img
                  src={InfoIcon}
                  className="infoIcon"
                  alt="More info"
                  onMouseEnter={() => setInfoHover(true)}
                  onMouseLeave={() => setInfoHover(false)} />
              </div>
            </div>
            <div className="originalityTitle">
              {strings.default[store.language].Recorder.Originality}
            </div>
            <div className="originalityScore">
              <div className="scoreStatus">
                {store.status.recorder.recording ?
                  <div className="dotContainer">
                    <div className="dot dot1" />
                    <div className="dot dot2" />
                    <div className="dot dot3" />
                  </div> : ''
                }
                {score !== '' ?
                  <div className="scoreWrapper">
                    <div
                      className={`scoreBar ${scoreGrade}`}
                      style={{ width: `${score}px` }} />
                    <div className={`scoreNumber score-${score}`}>
                      {`${score}%`}
                    </div>
                  </div> : ''
                }
              </div>
            </div>
          </div>

          <div className="originalityWrapper toneWrapper">
            <div className="originalityInfo">
              {toneHover &&
                <div className="infoBubble toneBubble">
                  {strings.default[store.language].Recorder.ToneHover}
                  <div className="triangle" />
                </div>
              }
              <div className="info">
                <img
                  src={InfoIcon}
                  className="infoIcon"
                  alt="More info"
                  onMouseEnter={() => setToneHover(true)}
                  onMouseLeave={() => setToneHover(false)} />
              </div>
            </div>
            <div className="originalityTitle">
              {strings.default[store.language].Recorder.Tone}
            </div>
            <div className="originalityScore toneScore">
              <div className="scoreStatus">
                {store.status.recorder.recording ?
                  <div className="dotContainer">
                    <div className="dot dot1" />
                    <div className="dot dot2" />
                    <div className="dot dot3" />
                  </div> : ''
                }
                {tone !== '' ?
                  <div className="scoreWrapper">
                    <div
                      className={`toneBar green positive
                ${determineSentiment(tone) === strings.default[store.language].Recorder.Neutral ? ' white' : ''}
                ${tone.positive === 1 ? ' full' : ''}
                ${tone.positive === 0 ? ' empty' : ''}`}
                      style={{ width: `${tone.positive * 100}px` }} />
                    <div
                      className={`toneBar red negative
                ${determineSentiment(tone) === strings.default[store.language].Recorder.Neutral ? ' white' : ''}
                ${tone.negative === 1 ? ' full' : ''}
                ${tone.negative === 0 ? ' empty' : ''}`}
                      style={{ width: `${tone.negative * 100}px` }} />
                    <div className="scoreNumber scoreTone">
                      {determineSentiment(tone)}
                    </div>
                  </div> : ''
                }
              </div>
            </div>
          </div>
        </>
      }

      <div className={`echoCancellation${props.cameraStarted ? ' active' : ''}`}>
        <span className="echoText">
          Echo cancellation:
        </span>
        <input
          className="echoCancel"
          type="checkbox"
          ref={refs.echoCancel}
          disabled={!props.cameraStarted || loadingCamera || mediaRecorded}
          checked={echoCancelled}
          onChange={() => { echoCancel() }} />
      </div>
      <div className={`recordedVideoWrapper${mediaRecorded ? ' showing' : ''}`}>
        <video
          className="recordedVideo"
          ref={refs.recordedVideo}
          playsInline
          controls={true} />
      </div>

      <ActionBar onClose={() => stopCamera(false, true)} />
    </div>
  )
});

export default Recorder; 
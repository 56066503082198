import { useContext, useState } from 'react';
import { Context } from '../../DataStore';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

import Reaction from '../common/Reaction';
import Vote from '../common/Vote';
import Participant from '../common/Participant';
import Video from '../common/Video';
import Keywords from '../common/Keywords';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/composite/AssetsView.scss';

const DefaultAvatar = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/default-robot.svg`;
const Download = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/download.svg`;
const DeleteIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/delete.svg`;
const DeleteIconRed = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/delete-red.svg`;
const CrownIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/crown.svg`;
const ChatBubble = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/chat-bubble.svg`;
const YoutubeIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/youtube.svg`;
const PrivateIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/private-white.svg`;

const AssetView = (props) => {
  const { store, dispatch } = useContext(Context);
  const history = useHistory();

  const [views, setViews] = useState([]);
  const [keywordLoading, setKeywordLoading] = useState(false);

  const sendMessage = (data) => {
    const statusCopy = {
      ...store.status,
      message: {
        type: data.type,
        text: data.text
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  };

  const openThread = (response) => {
    // Add to URL Route
    history.push(`/g/${store.status.community.cid}/${store.status.group.gid}/${store.status.space.sid}/${response.aid}/`);

    // Add to DataStore
    const statusCopy = {
      ...store.status,
      asset: response
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  const respondMain = () => {
    const magic = (store.status.space.magic !== undefined && store.status.space.magic);

    const statusCopy = {
      ...store.status,
      modal: {
        active: true,
        action: 'create',
        type: 'asset',
        store: 'assets',
        thread: null,
        data: {
          title: `${strings.default[store.language].AssetsView.RespondTo} ${store.status.space.creator.name.split(' ')[0]}`,
          inputTitle: strings.default[store.language].Content.ResponseTitle,
          privacySetting: false,
          magicAI: magic,
          creatorStudio: true,
          response: true
        }
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  const respondThread = (response) => {
    // Add to URL Route
    history.push(`/g/${store.status.community.cid}/${store.status.group.gid}/${store.status.space.sid}/${response.aid}/`);

    const magic = (store.status.space.magic !== undefined && store.status.space.magic);

    // Add to DataStore
    const statusCopy = {
      ...store.status,
      asset: response,
      modal: {
        active: true,
        action: 'create',
        type: 'asset',
        store: 'assets',
        thread: response.aid,
        data: {
          title: `${strings.default[store.language].AssetsView.SidebarWith} ${response.creator.name.split(' ')[0]}`,
          inputTitle: strings.default[store.language].Modal.ShortDescription,
          placeholder: strings.default[store.language].AssetsView.ThreadDescriptionPlaceholder,
          privacySetting: false,
          magicAI: magic,
          creatorStudio: true,
          response: true
        }
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  const deleteResponse = async (responseObj) => {
    try {
      let data = {
        cid: store.status.community.cid,
        gid: store.status.group.gid,
        sid: store.status.space.sid,
        aid: responseObj.aid,
        uuid: store.profile.uuid,
        sessionId: store.session.sessionId
      }
      const url = `${constants.services.url.api}/asset/modify/deactivate/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      if (response.ok) {
        if (responseData.status === 'Success') {
          let assetsDataCopy = { ...store.assets.data };
          let assetResponseCopy = [...assetsDataCopy.responses];
          const responseIndex = assetResponseCopy.map((response) => response.aid).indexOf(responseObj.aid);

          if (responseIndex > -1) {
            assetResponseCopy.splice(responseIndex, 1);
            assetsDataCopy.responses = assetResponseCopy;
          }

          let spacesDataCopy = [...store.spaces.data];
          const spacesIndex = spacesDataCopy.map((space) => space.sid).indexOf(store.status.space.sid);
          if (spacesIndex > -1) {
            let participantsCopy = [...spacesDataCopy[spacesIndex].participants];

            // Remove user from participants
            const creatorIndex = participantsCopy.map((participant) => participant.name).indexOf(store.profile.name);
            if (creatorIndex > -1) {
              participantsCopy.splice(creatorIndex, 1);
              spacesDataCopy[spacesIndex].participants = participantsCopy;
            }
          }

          // Add to DataStore
          const storeCopy = {
            ...store,
            assets: {
              data: assetsDataCopy,
              fetched: true
            },
            spaces: {
              data: spacesDataCopy,
              fetched: true
            }
          }
          dispatch({
            type: 'store',
            data: storeCopy
          });
        } else {
          sendMessage(constants.strings.messages('error', 'network'));
        }
      } else {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    } catch (error) {
      sendMessage(constants.strings.messages('error', 'network'));
    }
  }

  const urlify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return `
      <a 
        className="descriptionLink" 
        href="${url}" 
        target="_blank">
        ${url}
      </a>
      `;
    });
  }

  const processView = async (e, aid, creator) => {
    if (!views.includes(aid)) {
      setViews([
        ...views,
        aid
      ]);
      let data = {
        uuid: store.profile.uuid,
        sessionId: store.session.sessionId,
        aid: aid,
        creator: creator
      }

      const url = `${constants.services.url.api}/view/create/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      if (response.ok && responseData.status !== 'Success') {
        const index = views.indexOf(aid);
        if (index > -1) {
          const newViews = views.splice(index, 1);
          setViews(newViews);
        }
      }
    }
  }

  const openModalYoutube = (url) => {
    const statusCopy = {
      ...store.status,
      modal: {
        active: true,
        action: 'youtubeAttachment',
        data: {
          title: strings.default[store.language].AssetsView.YoutubeAttachment,
          spaceTitle: store.status.space.metadata.title,
          description: store.status.space.metadata.description,
          value: url,
          privacySetting: false
        }
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  const keywordInsight = async (keyword) => {
    setKeywordLoading(true);

    try {
      let data = {
        tokens: 50,
        subject: keyword,
        uuid: store.profile.uuid,
        sessionId: store.session.sessionId
      }

      const url = `${constants.services.url.api}/authenticity/ai/clue/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      setKeywordLoading(false);

      if (response.ok) {
        if (responseData.status === 'Success') {
          const statusCopy = {
            ...store.status,
            modal: {
              active: true,
              action: 'keyword',
              data: {
                title: keyword,
                description: responseData.content,
                youtube: responseData.youtube,
                news: responseData.news
              }
            }
          }
          dispatch({
            type: 'status',
            data: statusCopy
          });
        } else {
          sendMessage(constants.strings.messages('error', 'network'));
        }
      } else {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    } catch (error) {
      setKeywordLoading(false);
      sendMessage(constants.strings.messages('error', 'network'));
    }
  }

  return (
    <div className={`AssetsView${(constants.utils.isObject(store.status.asset) && !props.closing) || props.loadingDeeplink ? ' blurred' : ''}`}>
      <div className="scrollWrapper">

        <div className="layoutBuffer"></div>

        {/* Bright Village 3.1.0BV */}
        <div className="mediaWrapper">
          <div className="mediaWrapperInner">
            <div className="wrapperHeader">
              <div className="reactionWrapper">
                {(store.status.space.type === 'conversation' || store.status.space.spaceType === 'conversation') ?
                  <Reaction
                    aid={store.assets.data.aid}
                    data={store.assets.data.reactions} /> :
                  <Vote
                    aid={store.assets.data.aid}
                    data={store.assets.data.votes} />
                }
                {((constants.utils.isObject(store.status.space) &&
                  store.status.space.creator.uuid === store.profile.uuid)) &&
                  <div className="deleteWrapper">
                    <button
                      className="delete"
                      onClick={(event) => props.removeSpace(store.status.space, true, event)}>
                      <div className="deleteIcon">
                        <img
                          className="deleteImage"
                          src={DeleteIconRed}
                          alt="Delete media" />
                      </div>
                      <div className="deleteText">
                        {strings.default[store.language].Menu.ConfirmDeleteDiscussion}
                      </div>
                    </button>
                  </div>
                }
              </div>
            </div>
            <div className="headerContent">
              <div className="contentText">
                <div className="contentTitle">
                  {store.status.space.metadata.title}
                </div>
                <div className="contentData">
                  <div className="creator">
                    <img
                      className="creatorCrown"
                      src={CrownIcon}
                      alt="Speaker" />
                    <div
                      className="creatorAvatar"
                      style={{ backgroundImage: `url(${store.status.space.creator.image !== undefined && store.status.space.creator.image !== '' ? constants.services.s3ToCloudfrontMedia(store.status.space.creator.image) : DefaultAvatar})` }} />
                  </div>
                  <div className="metadata">
                    <div className="name">
                      {store.status.space.creator.name}
                    </div>
                    <div className="handle">
                      {store.status.space.creator.role}
                    </div>
                    <div className="creation">
                      {constants.utils.parseDateTime(store.status.space.creation)}
                    </div>
                  </div>
                </div>
                <div className={`contentDescription${(store.assets.data.data.keywords !== undefined && store.assets.data.data.keywords.length) ? ' hasKeywords' : ''}`}>
                  {parse(urlify(store.assets.data.data.description))}
                </div>
                {(store.assets.data.data.keywords !== undefined && store.assets.data.data.keywords.length) ?
                  <Keywords
                    data={store.assets.data.data.keywords}
                    loading={keywordLoading}
                    keywordInsight={(keyword) => keywordInsight(keyword)}
                    aid={store.assets.data.aid}
                    mainPrompt
                    update={{
                      allowed: store.status.space.creator.uuid === store.profile.uuid,
                      assetType: 'main'
                    }} /> : ''
                }
                <div className="respondWrapper">
                  <button
                    className={`respond${(store.status.space.magic !== undefined && store.status.space.magic) ? ' magic' : ''}`}
                    onClick={() => respondMain()}>
                    {`${(store.status.space.magic !== undefined && store.status.space.magic) ? '🪄 ' : ''}${strings.default[store.language].AssetsView.Respond}${store.status.space.creator.name.length ? `${strings.default[store.language].AssetsView.To} ${store.status.space.creator.name.split(' ')[0]}` : ''}`}
                  </button>
                </div>
              </div>
              <div className="contentMedia">
                <Video
                  className="videoPlayer"
                  setSrc={store.assets.data.data.location}
                  setPoster={`${store.assets.data.aid}.gif`}
                  onPlay={(e) => processView(e, store.assets.data.aid, store.assets.data.creator.uuid)}
                  transcript={store.assets.data.data.transcript !== undefined ? store.assets.data.data.transcript : ''}
                  width={520}
                  type={store.assets.data.data.type}
                  level="main" />
                <div className="attachmentWrapper">
                  {(store.status.space.metadata.youtube !== undefined && store.status.space.metadata.youtube !== '') &&
                    <div className="actionWrapper">
                      <button
                        className="youtubeLink"
                        onClick={() => openModalYoutube(store.status.space.metadata.youtube)}>
                        <div className="fileType">
                          <img
                            className="image"
                            src={YoutubeIcon}
                            alt="Watch Youtube" />
                          <div className="type">
                            {strings.default[store.language].AssetsView.YoutubeAttachment}
                          </div>
                        </div>
                      </button>
                    </div>
                  }
                  {store.assets.data.files.length > 0 &&
                    <>
                      {store.assets.data.files.map((file, i) => (
                        <div
                          className="actionWrapper"
                          key={`ActionWrapper${i}`}>
                          <a
                            className="fileLink"
                            key={file.aid}
                            href={file.data.location}
                            target="_blank"
                            rel="noreferrer"
                            alt="Attachment">
                            <div className="fileType">
                              <img
                                className="image"
                                src={Download}
                                alt="Download attachment" />
                              <div className="type">
                                {strings.default[store.language].AssetsView.DocumentAttachment}
                              </div>
                            </div>
                          </a>
                        </div>
                      ))}
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="counter">
          <div className="participantCount">
            {store.status.space.participants.length > 0 ? store.status.space.participants.length - 1 : 0} Responses
          </div>
          <div
            className="participantList"
            style={{ transform: `translateX(${12 * (store.status.space.participants.length < 21 ? store.status.space.participants.length - 1 : 22)}px)` }}>
            {store.status.space.participants.map((participant, i) => {
              if (participant.name !== store.status.space.creator.name) {
                //(store.status.space.participants.length - 1) > 19
                if (i <= 21) {
                  return (
                    <Participant
                      key={`Participant${i}`}
                      i={i}
                      participant={participant}
                      last={i === 21 ? store.status.space.participants.length - 21 : 0} />
                  )
                } else {
                  return '';
                }
              } else {
                return '';
              }
            })}
          </div>
        </div>

        <div className="responses">
          {store.assets.data.responses.length ?
            <div className="responsesWrapper">
              {store.assets.data.responses.map((response, i) => {
                if (response.data.private !== undefined &&
                  response.data.private &&
                  response.creator.uuid !== store.profile.uuid &&
                  store.status.space.creator.uuid !== store.profile.uuid) {
                  return '';
                } else {
                  return (
                    <div
                      className={`responseContainer${response.data.private !== undefined && response.data.private ? ' private' : ''}`}
                      key={`response${i}`}>
                      <div className="responseBorder">
                        {!response.data.private ?
                          <div className="reactionWrapper">
                            {(store.status.space.type === 'conversation' || store.status.space.spaceType === 'conversation') ?
                              <Reaction
                                aid={response.aid}
                                data={response.reactions} /> :
                              <Vote
                                aid={response.aid}
                                data={response.votes} />
                            }
                          </div> :
                          <div className="reactionWrapper">
                            <div className="privateConversation">
                              <img
                                src={PrivateIcon}
                                className="privateIcon"
                                alt="Private" />
                              {strings.default[store.language].Modal.Private}
                            </div>
                          </div>
                        }
                        <div
                          className="responseWrapper"
                          key={`ResponsesWrapper${i}`}>
                          <div className="response">
                            <Video
                              className="videoPlayer"
                              setSrc={response.data.location}
                              setPoster={`${response.aid}.gif`}
                              onPlay={(e) => processView(e, response.aid, response.creator.uuid)}
                              transcript={response.data.transcript !== undefined ? response.data.transcript : ''}
                              type={response.data.type}
                              level="response" />
                            {(response.creator.uuid === store.profile.uuid) &&
                              <button className="deleteResponse">
                                <img
                                  className="deleteIcon"
                                  src={DeleteIcon}
                                  onClick={() => deleteResponse(response)}
                                  alt="Delete media" />
                              </button>
                            }
                          </div>
                          <div className="data">
                            <div className="responderWrapper">
                              <div className="responder">
                                {store.status.space.creator.uuid === response.creator.uuid &&
                                  <img
                                    className="creatorCrown"
                                    src={CrownIcon}
                                    alt="Speaker" />
                                }
                                {response.creator.image === undefined || response.creator.image === '' ?
                                  <Participant
                                    i={0}
                                    participant={{
                                      name: response.creator.name
                                    }}
                                    display /> :
                                  <div
                                    className="responderAvatar"
                                    style={{ backgroundImage: `url(${response.creator.image !== undefined && response.creator.image !== '' ? constants.services.s3ToCloudfrontMedia(response.creator.image) : DefaultAvatar})` }} />
                                }
                              </div>
                              <div className="creation">
                                {constants.utils.parseDateTime(response.creation)}
                              </div>
                              <div className="metadata">
                                <div className="name">
                                  {response.creator.name}
                                </div>
                                <div className="handle">
                                  {response.creator.role}
                                </div>
                              </div>
                            </div>
                            <div className="descriptionWrapper">
                              <div className="triangleUp" />
                              <div className="description">
                                {response.data.description}
                              </div>
                              {response.data.keywords !== undefined && response.data.keywords.length ?
                                <Keywords
                                  data={response.data.keywords}
                                  loading={keywordLoading}
                                  keywordInsight={(keyword) => keywordInsight(keyword)}
                                  aid={response.aid}
                                  update={{
                                    allowed: response.creator.uuid === store.profile.uuid,
                                    assetType: 'response'
                                  }} /> : ''
                              }
                            </div>
                            <div className="viewResponsesWrapper">
                              <div className="respondButtonWrapper">
                                <button
                                  className={`respond${(store.status.space.magic !== undefined && store.status.space.magic) ? ' magic' : ''}`}
                                  onClick={() => respondThread(response)}>
                                  {`${(store.status.space.magic !== undefined && store.status.space.magic) ? '🪄 ' : ''}${strings.default[store.language].AssetsView.Respond}${response.creator.name.length ? `${strings.default[store.language].AssetsView.To} ${response.creator.name.split(' ')[0]}` : ''}`}
                                </button>
                              </div>
                              <button
                                className={`viewResponses${response.thread.length ? ' hasResponses' : ''}`}
                                onClick={() => openThread(response)}>
                                <div className="responsesText">
                                  {response.thread.length}
                                </div>
                                <img
                                  className="responsesIcon"
                                  src={ChatBubble}
                                  alt="Open responses" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              })}
            </div> :
            <div className="responseEmpty">
              {strings.default[store.language].AssetsView.NoResponses}
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default AssetView;
